import React from 'react'
//import { useNavigate } from 'react-router-dom'
import {motion} from "framer-motion"
import {useIntl } from 'react-intl';
//import Success from '../../assets/images/success.png'
import useWindowSize from '../hooks/useWindowSize'

const ModalSedes = (props) => {
  const intl = useIntl();
  const size = useWindowSize();
  const tablet = 768;
  const y = size.width > tablet?"-50%":"0";
  const x = size.width > tablet?"-50%":"0";

  return <div className="modal modalOK modalSedes">
    <motion.div animate={{ opacity:1, x: x, y: y, transition: { duration: 0.5, type: "spring", bounce: 0.5 }}} initial={{opacity:0, x: -200, y: y}} exit={{opacity:0, x:-600, transition: { duration: 0.2 } }} layout className="modalWrapper">
      <div className="modalContent">
            <div className="right">
                <div className="sedes">
                    <p>{ intl.formatMessage({ id: 'app.register.sedes1' }) }</p>
                    <table>
                        <thead>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.pais' }) }</td>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.deno' }) }</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.españa' }) }</td><td>Banco Santander, S.A.</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.alemania' }) }</td><td> Santander Consumer Bank AG</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.portugal' }) }</td><td> Banco Santander Totta</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.italia' }) }</td><td> Santander Consumer Bank SPA</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.polonia' }) }</td><td> Santander Bank Polska SA</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.noruega' }) }</td><td> Santander Consumer Bank AS</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.finlandia' }) }</td><td> Santander Consumer Finance Oy</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.suecia' }) }</td><td> Santander Consumer Bank AS - Sweden branch</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.dinamarca' }) }</td><td>Santander Consumer Bank AS - Denmark branch</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.belgica' }) }</td><td> Santander Consumer Finance - Belgium branch</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.holanda' }) }</td><td> Santander Consumer Finance - Dutch Branch</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.austria' }) }</td><td> Santander Consumer Holding Austria GmbH</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.francia' }) }</td><td> Santander Consumer Finance - France branch</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.grecia' }) }</td><td> Santander Consumer Finance- Greek Branch</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>{ intl.formatMessage({ id: 'app.register.sedes2' }) }</p>
                    <table>
                        <thead>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.pais' }) } </td>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.deno' }) }</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.uk' }) } </td> <td>B Santander UK plc</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.argentina' }) }</td><td> Banco Santander Argentina S.A.</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.canada' }) }</td><td> Santander Consumer Finance, Inc</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.suiza' }) }</td><td> Santander Consumer Finance Schweiz AG</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.uruguay' }) }</td><td> Banco Santander Uruguay SA</td>                            
                            </tr>
                        </tbody>
                    </table>
                    <p>{ intl.formatMessage({ id: 'app.register.sedes3' }) }</p>
                    <table>
                        <thead>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.pais' }) } </td>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.deno' }) }</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.brasil' }) } </td> <td>Banco Santander Brasil S.A.</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.chile' }) }</td><td> Banco Santander Chile S.A.</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.mexico' }) }</td><td> Banco Santander (México) S.A. <br/>
                                    Institución De Banca Múltiple <br/>
                                    Grupo Financiero Santander México</td>
                            </tr><tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.usa' }) }</td><td>Santander Holdings USA, Inc. <br/>
                                    Santander Bank, N.A. <br/>
                                    Santander Consumer USA Holdings, Inc.</td>                            
                            </tr>
                        </tbody>
                    </table>
                    <p>{ intl.formatMessage({ id: 'app.register.sedes4' }) }</p>
                </div>                
                <button onClick={()=>{props.close()}} className="btn"> 
                  {props.buttonText}
                </button>
            </div>
      </div>     
    </motion.div>
  </div>
}

export default ModalSedes