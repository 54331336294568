import React, { useState } from 'react'
import {useIntl} from 'react-intl'
import TeamInfo from './TeamInfo'
import ShareButton from './ShareButton'
import Button from '../common/Button'
import iconShareRed from '../../assets/icons/sharered.svg'
import iconX2 from '../../assets/icons/x2.svg'
import iconGift from '../../assets/icons/gift.svg'

const ShareOptions = (props) => {
    const [btShare, setBtShare] = useState(0)
    
    const intl = useIntl()

    const doubleShare = (
          <>
                    <div className='title'>{intl.formatMessage({ id: 'app.quiz.share.doubletitle' })}</div>
                    <div className='textTeam'>{intl.formatMessage({ id: 'app.quiz.share.doubletext' })}</div>                     
                    <ShareButton gp={props.gp} setBtShare={setBtShare}/>
                    <div className='or'>
                        <span className='line'>&nbsp;</span>
                        <span className='text'>{intl.formatMessage({ id: 'app.quiz.share.or' })}</span>
                        <span className='line'>&nbsp;</span>
                    </div>
                    <TeamInfo/>
                </>
    )

    const doubleShareClicked = (
            <>
                <div className='title'>{intl.formatMessage({ id: 'app.quiz.share.doubletitle' })}</div>
                <div className='textTeam'>{intl.formatMessage({ id: 'app.quiz.share.doubletextclicked' })}</div>
                <div className='x2'><img src={iconX2}/></div>
                <div className='or'>
                        <span className='line'>&nbsp;</span>
                        <span className='text'>{intl.formatMessage({ id: 'app.quiz.share.or' })}</span>
                        <span className='line'>&nbsp;</span>
                </div>                
                <Button color="white" icon={iconShareRed} clicked={() => {setBtShare(1)}} text={intl.formatMessage({ id: 'app.quiz.share.sharebutton' })}/>

            </>
    )

    const congrats = (
                        <>
                            <div className='title'>{intl.formatMessage({ id: 'app.quiz.share.congratstitle' })}</div>
                            <div className='textTeam'>{intl.formatMessage({ id: 'app.quiz.share.congratstext' })}</div>
                            <div className='gift'><img src={iconGift}/></div>
                            <div className='redline'>&nbsp;</div>
                            <TeamInfo congrats="true"/>
                        </>
    )

    return  <>
                <main className='share'>
                    <div className='shareWrapper'>
                        {btShare==0?
                            doubleShare
                        : 
                            null
                        }  
                        {btShare==1?
                            doubleShareClicked
                        : 
                            null
                        }   
                         {btShare==2?
                            congrats
                        : 
                            null
                        }   
                       
                    </div>
                </main>
            </>

}

export default ShareOptions