import {React, useEffect} from 'react'
//import axios from 'axios'
import { useIntl } from 'react-intl'
import { AnimatePresence, motion } from "framer-motion"

const RankingHeader = (props) => {
    const intl = useIntl()
    const hide=1;
    /*const [gps, setGps] = useState(null);
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });*/ 

    useEffect(() => {   
        /*console.log("props.gp", props.gp);
        instance.get('/api/gps')
        .then( (response) =>{
            console.log(response);
            setGps(response.data.gps)
        })
        .catch( (error) =>{   
            console.log(error);
        })*/
        
    }, []);

    const isActive = (id) => {
        var r = false;
        if (props.gps !== null){
            props.gps.forEach(item =>{
                if (item.active===true && parseInt(item.idgran_premio)===parseInt(id)) {
                    r = true;
                }
            })
        }
        return r;
    }

    const gpDisabled = () => {
        var n = 0;
        props.gpList.forEach( item =>{
            if (item.special==="1" && isActive(item.id)){
                n= n+1;
            }
        })
        return  n === 0;            
    }

    const gpsClasses = () => {
        var classes = "";
        if (props.active===0){
            classes = "gps active";
        } else {
            classes = "gps";
        }
        var n = 0;
        props.gpList.forEach( item =>{
            if (item.special==="1" && isActive(item.id)){
                n= n+1;
            }
        })

        if ( n === 0){
            classes = classes + " disabled";
        }
        return classes;
    }

    return (
        <div className="ranking-header">
            {<div className={gpsClasses() }>
                {gpDisabled()?<button className="coming-soon" disabled> { intl.formatMessage({ id: 'app.ranking.comingsoon' }) }</button>:
                <button disabled={props.gp===null} className={props.selectorOpen?"open":""} onClick={()=>{ props.onChangeActive(0); } }>                    
                    {props.gpList.map( (item)=>{
                        if (props.gp !== null){
                            if (item.id === props.gp) return  props.lang.toUpperCase()==="ES"?item.nombre:item.name
                            else return null
                        }else{                            
                            return null
                        }
                        }
                    )}
                </button>}
                <AnimatePresence>
                {props.selectorOpen && props.gps !== null?
                <motion.div animate={{ opacity:1, transition: { duration: 0.2 }}} initial={{opacity:0}} exit={{opacity:0, transition: { duration: 0.2 } }} layout className="GP-selector">
                    <ul>                        
                        {props.gpList.map(item=>{
                            if (item.special==="1" & isActive(item.id))
                            return <li key={"gplist-"+item.id}>
                                <button className={item.id===props.gp?"active":""} onClick={()=>props.onChangeGPActive(item.id)} >
                                    {props.lang.toUpperCase()==="ES"?item.nombre:item.name}
                                    </button>
                            </li>
                            else return null
                            }
                        )}
                    </ul>
                </motion.div>:null}
                </AnimatePresence>
            </div>}
            {<div className={props.active===1?"global active":"global"}>
                <button onClick={()=>{props.onChangeActive(1); } }> { intl.formatMessage({ id: 'app.ranking.season' }) }</button>
                {/*<span className="finished">{ intl.formatMessage({ id: 'app.ranking.finished' }) }</span>*/}
            </div>}
            {hide===0?<div className={props.active===2?"teams active":"teams"}>
                <button onClick={()=>{ props.onChangeActive(2); } }>{ intl.formatMessage({ id: 'app.ranking.team' }) }</button>
                {/*<span className="finished">{ intl.formatMessage({ id: 'app.ranking.finished' }) }</span>*/}
            </div>:null}
        </div>
    )

}
export default RankingHeader