import React, { useEffect, useState } from 'react'
import {  useIntl } from 'react-intl'
import { connect } from 'react-redux'
import flag  from '../../assets/images/flag.svg'
import time  from '../../assets/images/time.svg'
import team  from '../../assets/images/teams.svg'
import trophy  from '../../assets/images/trophy.svg'
import gift  from '../../assets/images/gift.svg'
import diamond  from '../../assets/images/diamond.svg'
import IntroContent from './IntroContent'
import { useNavigate } from 'react-router-dom'
import useWindowSize from '../hooks/useWindowSize'

const Intro = props => {
  const size = useWindowSize();
  const tablet = 768;
  let navigate = useNavigate();
  const intl = useIntl();
  const [active, setActive] = useState(1);
  var content = [
      {
          "title": intl.formatMessage({ id:"app.intro.thegame"}), 
          "text": intl.formatMessage({ id:"app.intro.participate"}),
          "image": flag
      },
      {
        "title": intl.formatMessage({ id:"app.intro.ranking"}),
        "text": intl.formatMessage({ id:"app.intro.answer"}),
        "image": time
      },
      {
        "title": intl.formatMessage({ id:"app.intro.teams"}),
        "text": intl.formatMessage({ id:"app.intro.invite"}),
        "image": team
      },
      {
        "title": intl.formatMessage({ id:"app.intro.circuits"}),
        "text": intl.formatMessage({ id:"app.intro.lookout"}),
        "image": trophy
      },
      {
        "title": intl.formatMessage({ id:"app.intro.rewards"}),
        "text": intl.formatMessage({ id:"app.intro.win"}),
        "image": gift
      },
      {
        "title": intl.formatMessage({ id:"app.intro.bonuses"}),
        "text": intl.formatMessage({ id:"app.intro.dontstop"}),
        "image": diamond
      },
  ]  

  useEffect(() => {
    props.setPage('intro');
  }, []); 
  
  const skip = () => {
      localStorage.setItem("INTRO", true);
      navigate("/dashboard");
  }
  const next = () =>{
    
    if (active + 1 > content.length){
        localStorage.setItem("INTRO", true);
        navigate("/dashboard");
    }else {
        setActive(active + 1);
    }
  }

  const getHeight = (n) => {
    if (size.width < tablet){
        if (n > 0 ){
            return {minHeight: (size.height - n)+"px"};
        }             
    }
    return null;
  }

  return <main className={"intro intro-"+active} style={ getHeight(1) }>
    <IntroContent 
        key={active}
        title={content[active-1].title}
        text={content[active-1].text}
        image={content[active-1].image}
        active={active}
        next={ ()=>{next()}}
        skip={()=>{skip()}}
    />   
  </main>
}
const mapStateToprops = (state)=>{        
  return {...state};  
}
export default connect(mapStateToprops)(Intro)