import React, { useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import ProgressBar from './ProgressBar'
import CircleTimer from './CircleTimer'
import TeamRadio from './TeamRadio'
import Casco from "../common/Casco"
import HeaderGp from "./HeaderGp"
import axios from 'axios'
import Chrono from './Chrono'
import {TextFormat} from '../common/TextFormat'
import {motion, AnimatePresence} from "framer-motion"
import {useIntl} from 'react-intl'
import ModalCustom from '../common/ModalCustom'
import Radio from '../common/Radio'
import iconExit from '../../assets/icons/exit.svg'
import iconPlus3 from '../../assets/icons/plus3.svg'
import iconMinus3 from '../../assets/icons/minus3.svg'
import iconSlipStreaming from '../../assets/icons/slipstreaming.svg'
import iconPitStop from '../../assets/icons/pitstop.svg'
import Loading from '../../assets/images/audio.svg'

const Question = (props) => {
	
    const [dQuestion,setDquestion] = useState(null);
	const [colorOpcion, setColorOpcion]=useState(["color-opcion","color-opcion","color-opcion"])
	const [statusComodin,setStatusComodin]=useState(false)
	const [opcionComodin,setOpcionComodin]=useState(-1)
	const [number, setNumber]=useState(1)
	const [statusChrono, setStatusChrono] = useState(0)
	const [modalBoost, setModalBoost] = useState(0)
	const [boost, setBoost] = useState(false)
	const [pitstop, setPitStop] = useState(false)
	const [timeChrono, setTimeChrono] = useState(0)
	
	const intl = useIntl()
	let navigate = useNavigate();
	const tiempoParado=2000
	const duration=20
	const variants = {
		child0: {opacity:1, scale:1, x:0, transition: { delay:0, duration: 0.7, type: "spring", bounce: 0.5 }},
		child1: {opacity:1, scale:1, x:0, transition: { delay:0.3, duration: 0.7, type: "spring", bounce: 0.5 }},
		child2: {opacity:1, scale:1, x:0, transition: { delay: 0.6, duration: 0.7, type: "spring", bounce: 0.5 }},
		child3: {opacity:1, scale:1, x:0, transition: { delay: 0.9, duration: 0.7, type: "spring", bounce: 0.5 }}
	}
	
	/**********************************
	 * meto el jwt manual para las pruebas
	 * **********************************/
	const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,		
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

	const getIndexRespuesta = (msg,opciones) => {
		var res=-1
		opciones.forEach((op,index) => {
			if(op==msg){
				res = index
			}
		})
		return res
	}

	const checkModalBoost = (historico) => {

		var cad=historico.toString()
		cad=cad.replaceAll(',','')
		
		if(cad.indexOf("111")!==-1 && cad.length > 2) {
			//console.log()
			return true
		}
		if(historico.length === 11){
			return false
		}
		return false
	}

	const checkModalPitStop = (historico) => {

		var cad=historico.toString()
		cad=cad.replaceAll(',','')
		if(cad.indexOf("000")!==-1 && cad.length > 2) {
			return true
		}

		if(historico.length === 5){
			return false
		}
		return false
	}

	const procesarDatosRespuesta = (tres_correctas, tres_erroneas, tiempoTotal, index, index_correcta,historico) => {
		setTimeChrono(tiempoTotal)
		setStatusChrono(2)
	
		const mostrarBoost=checkModalBoost(historico)
		if(tres_correctas===1 && !boost && !mostrarBoost ){
			setModalBoost(1)
			setBoost(true)
		}
		const mostrarPitStop=checkModalPitStop(historico)
	
		if(tres_erroneas==1 && !pitstop && !mostrarPitStop){
			setModalBoost(2)
			setPitStop(true)
		}
	
		//esperamos unos segundos y cambiamos de pregunta y volvemos al color original
		setTimeout( () => {
			if(statusComodin && opcionComodin!==-1){
				colorRespuesta(opcionComodin,"reset")
			}
			colorRespuesta(index,"reset")
			colorRespuesta(index_correcta,"reset")
			if(historico.length < 9){
				setStatusChrono(1)
			}
			setOpcionComodin(-1)
			setModalBoost(0)
			//if(props.number!==props.numberQuestions){
				getQuestion();
			//}
		},tiempoParado);
	}

  function getQuestion() {
		//console.log(statusChrono)
		instance
			.get("api/quiz/" + props.number)
			.then((response) => {
				//console.log(response.data);
				if (response.data.messages.success === "Quiz finished") {
					props.quizFinished(response.data);
				} else {
				TextFormat.removeSpecialChars(
						response.data.data
					).then( (datas) =>{
						response.data.data = datas;
						setDquestion(response.data);
						setTimeChrono(response.data.data.tiempoTotal);
						setStatusChrono(0);

						const data = response.data.data;
						const mostrarBoost = checkModalBoost(data.historico);
						if (
							data.comodines.tres_correctas === 1 &&
							!boost &&
							!mostrarBoost
						) {
							setModalBoost(1);
							setBoost(true);
						}

						const mostrarPitStop = checkModalPitStop(data.historico);
						if (
							data.comodines.tres_erroneas == 1 &&
							!pitstop &&
							!mostrarPitStop
						) {
							setModalBoost(2);
							setPitStop(true);
						}

						if (data.historico !== undefined) {
							setNumber(data.historico.length + 1);
						}
						//si ya ha usado comodin lo ponemos en gris
						if (data.comodines.quitar_una === 1) {
							setStatusComodin(true);
						}

					});
					
				}
			})
			.catch((error) => {
				//console.log(error);
				if (error.response?.data.status === 404) {
					navigate("/dashboard");
				}
				//////timeout
				if (error.response?.data.status === 503) {
					const data = error.response.data.data;
					const comodines = data.comodines;

					if (dQuestion === null) {
						TextFormat.removeSpecialChars(
							error.response.data.data
						).then( (datas) =>{
							error.response.data.data = datas;
							setDquestion(error.response.data);
						});
					} else {
						data.historico.pop();
					}

					let index_correcta = getIndexRespuesta(data.correcta, data.opciones);

					colorRespuesta(index_correcta, "correcta");

					procesarDatosRespuesta(
						comodines.tres_correctas,
						comodines.tres_erroneas,
						data.tiempoTotal,
						-1,
						index_correcta,
						data.historico
					);
				} else {
					//setStatus(error.response !== undefined ? error.response.status : "desconocido" );
				}
			});
 }

	useEffect(()=>{ 
		getQuestion()
	}, [])

	const colorRespuesta = (index,status) => {

		var color=colorOpcion
		if(status==="reset"){
			color[index]="color-opcion"
		}else{
			color[index]="color-opcion-"+status
		}
		setColorOpcion(color)
	}

	const cambiarRespuestaComodin = (index) => {
		var mQuestion=dQuestion

		const msgComodin=(
			<div className="opcion-comodin">
                    <div className="icon-team-opcion-comodin">
					
                        <Radio/>
                    </div>
                    <div className="texto-team-opcion-comodin">
						<strong>{intl.formatMessage({ id: 'app.quiz.teamradio' })}: </strong>
						<p> {intl.formatMessage({ id: 'app.quiz.noquestionradio' })}
						<strong> {intl.formatMessage({ id: 'app.quiz.chooseradio' })}</strong>
						</p>
						
					</div>
                </div>
		)
		mQuestion.data.opciones[index]=msgComodin
		setDquestion(mQuestion)
	}

	function usarComodin(){
		//solo usamos comodin sino lo ha usado ya
		if(dQuestion.data.comodines.quitar_una !== 1){
			setStatusComodin(true)

			instance.get('api/quiz/use/'+props.number).then( (response) => {
				//console.log(response);
				let erronea=response.data.data.remove;
				let index=getIndexRespuesta(erronea, dQuestion.data.opciones)
				
				if(index!==-1){
					//cambiamos opción por el team radio
					setOpcionComodin(index)
					colorRespuesta(index,"team-radio")
					cambiarRespuestaComodin(index)
				}
				
			})
			.catch( (error) => {
				console.log(error);
			});
		}
	}

	function responder(index){
	
		//solo mandamos la respuesta al servidor si no pulsa el comodin
		if(!(statusComodin && index===opcionComodin) && !props.showModal && statusChrono!==2) {
			var respuesta=dQuestion.data.opciones[index]
			if(index===-1){
				respuesta=""
			}
			instance.post('api/quiz/answer/'+props.number, {
				codigo: dQuestion.data.codigo,
				respuesta: respuesta,
				segundos: ""
			})
			.then( (response) => {
				var index_correcta=-1
				//console.log(response.data)
				
				if(response.data.messages.success==="ok"){
					index_correcta=getIndexRespuesta(response.data.data.correcta,dQuestion.data.opciones)
					colorRespuesta(index_correcta,"correcta") 

					if(index!==index_correcta){
						colorRespuesta(index,"erronea")
					}
				}
				const data=response.data.data
				const comodines=data.comodines

				data.historico.pop()
				
				procesarDatosRespuesta(comodines.tres_correctas, comodines.tres_erroneas,
										data.tiempoTotal, index, index_correcta, data.historico)
				
				setNumber(number+1)
				if(number>props.numberQuestions){
					setNumber(props.numberQuestions)
				}
			})
			.catch( (error) => {
				if(error.error==="Timeout"){
					if(statusComodin && opcionComodin!==-1){
						colorRespuesta(opcionComodin,"reset")
					}
					getQuestion();
					setNumber(number+1)
					if(number>props.numberQuestions){
						setNumber(props.numberQuestions)
					}
				}
				/*console.log(error.toJSON());*/
			
			});			
		}
	}
	const finishTimer = () => {
		if(number !== props.numberQuestions){
			if(statusComodin && opcionComodin!==-1){
				colorRespuesta(opcionComodin,"reset")
			}
			//responder(-1)
			getQuestion()
			//setNumber(number+1)
		}
	}

	const leaveRace = () => {
		instance.post('api/quiz/pause/'+props.number, {
			codigo: dQuestion.data.codigo
		})
		.then( (/*response*/) => {
			//console.log(response.data)
			navigate("/dashboard");  
		})
		.catch( (error) => {
			console.log(error.toJSON());
		});
	}

	const continueRace = () => {
		props.setShowModal(false)
	}
	/*if(status!==200 && status !== 503){
		return null
	}*/

	if(dQuestion===null || dQuestion.data.codigo === undefined  ){
		return <div className="spinner" src={Loading} />
	} 

	return  <> 
				<AnimatePresence >
				{props.showModal ? <ModalCustom icon={iconExit} 
						btnTextClose={intl.formatMessage({ id: 'app.quiz.modalleave' })} 
						title={intl.formatMessage({ id: 'app.quiz.leaverace' })} 
						onClose={leaveRace} btnTextContinue={intl.formatMessage({ id: 'app.quiz.modalcontinue' })} onContinue={()=> {continueRace()}}/> 
				: null }
				{modalBoost==1 && !props.showModal ? <ModalCustom iconHeader={iconMinus3} icon={iconSlipStreaming} css="SlipStreaming"
													title={intl.formatMessage({ id: 'app.quiz.slip' })} text={intl.formatMessage({ id: 'app.quiz.slipstreaming' })}/> 
				:null}
				{modalBoost==2 && !props.showModal ? <ModalCustom iconHeader={iconPlus3} icon={iconPitStop} css="PitStop"
													title={intl.formatMessage({ id: 'app.quiz.pit' })} text={intl.formatMessage({ id: 'app.quiz.pitstop' })}/> 
				:null}
				</AnimatePresence>
				<div className={"question" + " fondo"+number}>
					<div className='progressQuestion'>
						<ProgressBar historico={dQuestion.data.historico} numPreguntas={props.numberQuestions} /> 
					</div>
					<div className='progress-header'>
						<div className='header-desktop-wrapper'>
							<HeaderGp idgp={props.number} />
						</div>
						<div className='progress-desktop-wrapper'>
							<ProgressBar historico={dQuestion.data.historico} numPreguntas={props.numberQuestions} /> 
						</div>
						<div className='casco-desktop-wrapper'>
							<Casco
									id={props.user.id.idusuario}
									nick={props.user.id.nickname}
							></Casco>
						</div>
					</div>
					<div className="headerQuestion">
						<div className="numberContainer">
							<div className="circleContainer">
								<CircleTimer key={dQuestion.data.codigo} time={dQuestion.data.tiempo} duration={duration} number={dQuestion.data.historico.length+1} onFinish={finishTimer}><></></CircleTimer>
							</div>
							<div className='txtOf'>{intl.formatMessage({ id: 'app.quiz.of' }) + " " + props.numberQuestions}</div>
						</div>
					
						<div className="chronoContainer">
							<Chrono time={timeChrono} status={statusChrono} key={"chrono-"+dQuestion.data.tiempoTotal}/>
						</div>
					</div>
					<div className="containerQuestion">
						<div className="pregunta">
							{dQuestion.data.pregunta}
							{dQuestion.data.opciones.length>2 ?
								<div className='teamradio-desktop'>	
									<TeamRadio status={statusComodin} onClickTeamRadio={usarComodin}/>
								</div>
							:''}	
						</div>
						<div className='opcionWrapper'>
							<AnimatePresence >
								<motion.ul className="listado-opcion" animate={{ transition:{delayChildren:0.3}}}>
								{dQuestion.data.opciones.map((op,key) =>
									dQuestion.data.opciones!=='' ?
									<motion.li variants={variants} animate={"child"+key} initial={{opacity:0, scale:1, x: -200}} exit={{opacity:0, scale:1, transition: { duration: 0 } }} layout 
										name={'opcion-'+key} className={'opcion ' + colorOpcion[key]} key={op+key} onClick={(e)=> {
											responder(key,e)
										}}>{op}</motion.li>
									:''
								)}
								</motion.ul>
							</AnimatePresence>		
						</div>
						
					</div>
					{dQuestion.data.opciones.length>2 ?
						<div className='teamradio-mobile'>	
							<TeamRadio status={statusComodin} onClickTeamRadio={usarComodin}/>
						</div>
					:''}
				</div>
            </>
}

export default Question