import React from 'react'
import {motion} from "framer-motion"
import {useIntl } from 'react-intl';
import useWindowSize from '../hooks/useWindowSize'

const ModalSedes = (props) => {
  const intl = useIntl();
  const size = useWindowSize();
  const tablet = 768;
  const y = size.width > tablet?"-50%":"0";
  const x = size.width > tablet?"-50%":"0";

  return <div className="modal modalOK modalSedes">
    <motion.div animate={{ opacity:1, x: x, y: y, transition: { duration: 0.5, type: "spring", bounce: 0.5 }}} initial={{opacity:0, x: -200, y: y}} exit={{opacity:0, x:-600, transition: { duration: 0.2 } }} layout className="modalWrapper">
      <div className="modalContent">
            <div className="right">
                <div className="sedes">
                    <table>
                        <thead>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.pais' }) }</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.españa' }) }</td><td>< a href="mailto:privacidad@gruposantander.es">privacidad@gruposantander.es</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.portugal' }) }</td><td> <a href="mailto:privacidad@santander.pt">privacidad@santander.pt</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.uk' }) } </td> <td>DPO at 201 Grafton Gate East, Milton Keynes, MK9  1AN</td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.usa' }) }</td><td>Mail code: RI 1 EPV 02 18<br/>1-877-768-2265</td>                            
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.brasil' }) } </td> <td><a href="mailto:charge.lgpd@santander.com.br">charge.lgpd@santander.com.br</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.alemania' }) }</td><td> <a href="mailto:datenschutz@santander.de">datenschutz@santander.de</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.polonia' }) }</td><td> <a href="mailto:tfi@santander.pl">tfi@santander.pl</a></td>
                            </tr>                            
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.italia' }) }</td><td><a href="mailto:tfi@santander.pl">tfi@santander.pl</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.mexico' }) }</td><td><a href="mailto:unidad_pd@santander.com.mx">unidad_pd@santander.com.mx</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.chile' }) }</td><td><a href="mailto:derechosarco@santander.cl">derechosarco@santander.cl</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.argentina' }) }</td><td><a href="mailto:protección_de_datos_personales@santander.com.ar">protección_de_datos_personales@santander.com.ar</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.uruguay' }) }</td><td><a href="mailto:atencionalcliente@santander.com.uy">atencionalcliente@santander.com.uy</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.francia' }) }</td><td><a href="mailto:dposcb@santanderconsumer.fr">dposcb@santanderconsumer.fr</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.canada' }) }</td><td><a href="mailto:privacyofficer@santanderconsumer.ca">privacyofficer@santanderconsumer.ca</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.grecia' }) }</td><td><a href="mailto:dataprotection@santanderconsumer.com">dataprotection@santanderconsumer.com</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.finlandia' }) }</td><td><a href="mailto:dpo@santanderconsumer.fi">dpo@santanderconsumer.fi</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.holanda' }) }</td><td> <a href="mailto:privacy@santander.nl">privacy@santander.nl</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.suiza' }) }</td><td> <a href="mailto:clientservice@santanderconsumerfinance.ch">clientservice@santanderconsumerfinance.ch</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.suecia' }) }</td><td> <a href="mailto:kundombudsman@santanderconsumer.se">kundombudsman@santanderconsumer.se</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.austria' }) }</td><td> <a href="mailto:ombudsteam@santanderconsumer.at">ombudsteam@santanderconsumer.at</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.belgica' }) }</td><td> <a href="mailto:Dataprotectionofficer@cnpsantander.com">Dataprotectionofficer@cnpsantander.com</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.dinamarca' }) }</td><td><a href="mailto:kundeservice@santanderconsumer.dk">kundeservice@santanderconsumer.dk</a></td>
                            </tr>
                            <tr>
                                <td>{ intl.formatMessage({ id: 'app.register.sedes.noruega' }) }</td><td> <a href="mailto:kundeombud@santanderconsumer.no">kundeombud@santanderconsumer.no</a></td>
                            </tr>                          
                        </tbody>
                    </table>                    
                </div>                
                <button onClick={()=>{props.close()}} className="btn"> 
                  {props.buttonText}
                </button>
            </div>
      </div>     
    </motion.div>
  </div>
}

export default ModalSedes