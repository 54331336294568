import {React, useEffect, useState} from 'react'
import {Countries} from './Countries'

const CountrySelector = (props) => {
    const [countries, setCountries] = useState(null);
    //const allowedCountries = ['ES', 'UK', 'PT', 'IT', 'PL', 'NO', 'FI', 'SE', 'DK', 'BR', 'CL', 'MX', 'US', 'AR', 'UY' ]
    //España, UK, Alemania, Portugal, Italia, Polonia, Noruega, Finlandia, Suecia, Dinamarca, Brasil, Chile, México, USA, Argentina y Uruguay (total 16 países)    

    //NEW: Grecia; Canadá; Bélgica; Suiza; Holanda; Austria; Francia;

    useEffect(() => {
        /* 'https://restcountries.com/v2/all' //https://restcountries.com/v3.1/all?fields=name,languages,cca2 */
        var co = Countries.getCountries();
        setCountries(co);
       
    },[]);

    const selectCountry = (country) => {
        props.onSelectCountry(country);
    }    

return <>    
    {
        (countries!== null & props.show)?<>
            <div className="countries">
                {countries.map((country, index) => {
                    return (<div onClick={ ()=> selectCountry(country)} 
                        className={ props.country.alpha2Code===country.alpha2Code?"country selected":"country" }
                        key={index+"-"+country.name}>
                        <div className="flag">
                            <img src={process.env.PUBLIC_URL+"images/more-flags/" + country.alpha2Code + ".svg"} alt={country.name} />
                        </div>
                        <span>{country.name}</span> 
                    </div>)
                })}
            </div>
        </>:null
    }    
    </>
}
export default CountrySelector