import {React, useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import {useIntl} from 'react-intl'
import { AnimatePresence, motion } from "framer-motion"
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import Casco from '../common/Casco'
import RankingHeader from './RankingHeader'
import MyRanking from './MyRanking'
import iconShare from "../../assets/icons/sharegray.svg";
import ShareButton from "../common/ShareButton";
import ModalCustom from "../common/ModalCustom";


const Rankings = (props) => {
    let navigate = useNavigate();
    let { state } = useLocation();
    const [ranking, setRanking] = useState(null);
    const [gps, setGps] = useState(null);
    const [active, setActive] = useState(1);
    const [gpactive, setGPActive] = useState(null);
    const [gpSelectorOpen, setGpSelectorOpen] = useState(false);   
    const [modal,setModal] = useState(false)
    const [url, setUrl] = useState(null)
    const [loading, setLoading] = useState(true);
    const intl = useIntl()

    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 26000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
      }); 

      const getRankingFromAPI = () => {
        /*if (!localStorage.getItem('RANKING-DATE') || !localStorage.getItem('RANKING')){
            return true;
        } else{
            var ranking_date = new Date(localStorage.getItem('RANKING-DATE'));
            //console.log(ranking_date);
            ranking_date.setHours(ranking_date.getHours() + 1 );
            if (ranking_date < new Date()){
                return true;
            }
        }
        return false;*/
        return true;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        props.setPage('ranking');  
        if (getRankingFromAPI()){
            instance.get('/api/ranking')
            .then( (response) =>{
                //console.log(response.data);            
                setRanking(response.data.ranking);
                //localStorage.setItem('RANKING', JSON.stringify(response.data.ranking));
                //localStorage.setItem('RANKING-DATE', new Date());
                /*response.data.ranking.GP.forEach( (item, index)=>{
                    if (index===0){
                        setGPActive(item.id);
                    }
                })*/
                if (state != null){
                    if(!state.season && state.idgp !== undefined){
                        //console.log("state", state)
                        setGPActive(state.idgp)
                        setActive(0)
                    }
                }
                setLoading(false);

            })
            .catch( (error) =>{   
                console.log(error);
                navigate("/login");            
            })
        } else {
            setRanking(JSON.parse(localStorage.getItem('RANKING')));
            if (state != null){
                if(!state.season && state.idgp !== undefined){                
                    setGPActive(state.idgp)
                    setActive(0)
                }
            }
            setLoading(false);
        }

        instance.get('/api/gps')
        .then( (response) =>{ 
            setGps(response.data.gps);
            var found = false;
            response.data.gps.forEach( (item)=>{
                //console.log("item check", index, item.special, item.active, index===0 && item.special==="1" && item.active);                
                if (!found && item.special==="1" && item.active){
                    setGPActive(item.idgran_premio);
                    found = true;
                }
            })
        })
        .catch( (error) =>{   
            console.log(error);
            navigate("/login");            
        })

        
    }, []);

    //Close Filters Dropdown on outer click
    useEffect(() => {
        const checkCloseSelector = e => {
        var closest = e.target.closest(".gps");         
        if ( gpSelectorOpen && closest === null) {
            setGpSelectorOpen(false);
        }
        }  
        document.addEventListener("mousedown", checkCloseSelector)
        return () => {        
        document.removeEventListener("mousedown", checkCloseSelector)          
        }
    }, [gpSelectorOpen])
    
    //ge state from navigate
    useEffect(() => {        
        
    }, []);

    const changeActive = (i) =>{
        if (i===0){
            setGpSelectorOpen(!gpSelectorOpen);
        } else {
            setGpSelectorOpen(false);
        }
        setActive(i);
    }

    const changeGPActive = (i) => {
        setGPActive(i);
        setGpSelectorOpen(false);
    }

    const getCurrentRanking = () =>{
        if (active=== 1){
            return ranking.Global.ranking
        } 
        if (active === 0){
            var r = null;
            ranking.GP.forEach(element => {    
                if (element.id === gpactive)
                    r = element.ranking;
            });            
            return r;
        }
        if (active === 2){
            return ranking.Teams.ranking;
        }
    }

    const getCurrentMyRanking = () =>{
        //return [];
        if (active=== 1){
            return ranking.Global.current_user
        } 
        if (active === 0){
            var r = null;
            ranking.GP.forEach(element => {    
                if (element.id === gpactive)
                    r = element;
            });            
            return r.current_user;
        }
        if (active === 2){
            
            if (ranking.Teams.current_user[0].idequipo > 0)
                return ranking.Teams.current_user;
            else return [];
        }
    }

    const itsMe = (item) => {
        var found = false;
        if (active === 2){
            if (props.user!== null){
                if (item.idusuarioCreador === props.user.id.idusuario){
                    found =  true;
                }
            }
            var teams = getCurrentMyRanking();
            teams.forEach(i =>{
                if (i.idequipo == item.idequipo && i.idequipo > 0 ){
                    found =  true;
                }
            })
        } else{
            if (props.user!== null){
                if (item.idusuario === props.user.id.idusuario){
                    found =  true;
                }
            }
        }
        return found;
    }

    const getClass = (item, index) =>{
        var c = "";
        if (index+1 === 1){
            c = "position first ";
        } else {
            c=" position ";
        }
        if (itsMe(item)){
            c= c + " me ";
        }
        return c;
    }

    const copyLink = () => {
        //console.log(url)
        navigator.clipboard.writeText(url)
        setModal(false)
    }

    const getClassCasco = (item) => {
        if (itsMe(item)){
            //console.log(props.user.id);
            return props.user.id.profileCompleted===5?"casco":"casco black-white";
        }
        return "casco";
    }

    /*const reload = () => {
        setLoading(true);
        instance.get('/api/ranking')
        .then( (response) =>{               
            setRanking(response.data.ranking);
            localStorage.setItem('RANKING', JSON.stringify(response.data.ranking));
            localStorage.setItem('RANKING-DATE', new Date());               
            if (state != null){
                if(!state.season && state.idgp !== undefined){                    
                    setGPActive(state.idgp)
                    setActive(0)
                }
            }
            setLoading(false);
        })
        .catch( (error) =>{   
            console.log(error);
            navigate("/login");            
        })
    }*/

    return (<main className="rankings"> 
            <AnimatePresence>
                { modal && url !== null?
                    <ModalCustom title={intl.formatMessage({ id: 'app.quiz.share.modaltitle' })} text={url}
                    onClose={copyLink} btnTextContinue={intl.formatMessage({ id: 'app.quiz.share.copy' })} onContinue={copyLink} />
                : null}
            </AnimatePresence> 
            { (loading === true )?<div className="spinner-wrapper">< div className="spinner"/></div>:null }      
            {ranking !== null && gps!==null?<>
            <RankingHeader 
                lang={props.user===null?intl.formatMessage({ id: 'app.demo.lang' }):props.user.id.idioma} 
                active={active} 
                selectorOpen = {gpSelectorOpen}
                gpList = {ranking.GP}
                gps={gps}
                gp={gpactive} 
                onChangeGP={ (i) =>setGPActive(i) } 
                onChangeGPActive={ (i)=>changeGPActive(i) } 
                onChangeActive={ (i)=>changeActive(i) }>                    
            </RankingHeader>
            <div className="ranking-container">
                <AnimatePresence>
                    { gpSelectorOpen?<motion.div animate={{ opacity:1, transition: { duration: 0.2 }}} initial={{opacity:0}} exit={{opacity:0, transition: { duration: 0.2 } }} layout className="blur"></motion.div>:null}
                </AnimatePresence>
                <div className="ranking">
                    {
                    getCurrentRanking()!==null?
                    getCurrentRanking().map( (item, index) =>{                        
                        return (<div className={ getClass(item, index)/*index+1 === 1?"position first":"position"*/} key={"position-"+index}>
                            <div className="order-wrapper">
                            <div className="order">                                
                                    <span>{index+1}</span>
                                </div>
                            </div>
                            <div className="user-info">
                                <div className="left">
                                    {active===2?<Casco id={item.idusuarioCreador} nick={item.nombre}></Casco>:
                                    <Casco className={getClassCasco(item)} id={item.idusuario} nick={item.nickname}></Casco>}
                                </div>
                                <div className="center">
                                    <span className="nick">{ active===2?item.nombre:item.nickname}</span>
                                    <div className="bottom">
                                        {active===0?
                                            <>
                                                <span className="hits">{item.totalAciertos} hits</span>
                                                <span className="time">{item.totalTiempo}&apos;&apos;</span>
                                            </>:
                                            <>
                                                <span className="hits">{item.aciertos} hits</span>
                                                <span className="time">{item.tiempo}&apos;&apos;</span>
                                            </>
                                        }
                                    </div>                        
                                </div>
                                {itsMe(item)?<div className="shareMe">
                                    <ShareButton
                                        active={active}
                                        gp={gpactive}
                                        id={item.id}
                                        icon={iconShare}
                                        modalOutside={true}
                                        setUrl={setUrl}
                                        setModal={setModal}
                                    />
                                </div>:null}                               
                            </div>
                        </div>)
                    }):null}                      

                </div> 
                {active===2?<>
                        <div className="myTeams">
                        {
                           
                            getCurrentMyRanking().map( (item, index)=>{
                                return <MyRanking key={"team-ranking-"+index} active={active} user={props.user} data={item} gp={gpactive}></MyRanking>
                            })
                            
                        }
                        </div>
                        </>:
                        <MyRanking active={active} user={props.user} data={getCurrentMyRanking()} gp={gpactive}></MyRanking>                    
                    }
            </div>            
            </>:null}  
            {/*<button disabled={loading} className="btn" onClick={()=>{ reload(); } }>{intl.formatMessage({ id: 'app.ranking.reload' })}</button>*/}
            <span className="update">{ intl.formatMessage({ id: 'app.ranking.update'}) }</span>
    </main>)
}
const mapStateToprops = (state)=>{        
    return {...state};  
}
export default connect(mapStateToprops,{setUser:setUser})(Rankings)