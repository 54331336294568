import React, { useState } from 'react'
/*import { Link } from 'react-router-dom';*/
import Question from './Question'
import {useIntl} from 'react-intl'
import WarmLap from '../../assets/images/warm-lap.svg'
import HeaderGp from "./HeaderGp";
import Casco from "../common/Casco";
import iconHit from "../../assets/icons/hit.svg";
import iconErrors from "../../assets/icons/errors.svg";
import iconTimer from "../../assets/icons/timer.svg";
import Button from "../common/Button";


const Questions = (props) => {
	
	const [nQuestion,setNquestion] = useState(0)
	const [finish, setFinish] = useState(false)	
	const numberQuestions = 10
	const intl = useIntl()
	const finishedQuiz = (t, h) => {
		setFinish(true)
		setTime(t);
		var a = 0;
		h.forEach( item =>{
			if (item==="1") a++;
		});
		setAciertos(a);
		//console.log("historico",h, "time", t);
	}	
	const gp = {id:props.gp};
	const [time, setTime] = useState(0);
	const [aciertos, setAciertos] = useState(0);
	
	//console.log("Questions GP", props.gp);

	if(finish){
		return 	<>
					{/*<main className="demo finish">
						<h2>{ intl.formatMessage({ id:"app.demo.wow"})}</h2>
						<p>{ intl.formatMessage({ id:"app.demo.prove"})}</p>
						<Link to="/login">{ intl.formatMessage({ id:"app.entrar"})}</Link>
					</main>*/}
					<main className="quiz bg-finish">
						<div className="quiz-title">
							<div className="center">
								<h2 className="ready">{intl.formatMessage({ id: "app.quiz.finish" })}</h2>
							</div>
						</div>
						<div className={props.alreadyFinished?"finish-wrapper already-finished":"finish-wrapper first-time-finished"}>
							<div className="gp-head">
								<div className="country-wrapper">
									<HeaderGp idgp={props.gp} />
								</div>
								<div className="ready-wrapper">
								<h2 className="ready">
									{intl.formatMessage({ id: "app.quiz.finish" })}
								</h2>
								</div>
							</div>
							<div className="finish-metrics">
								<div className="">
									{gp !== null && gp.special === "0" ? (
										<div className="season-msg">
											<span className="">
												{intl.formatMessage({ id: "app.quiz.rankedin" })}
											</span>
										</div>
									) : null}
									<div className="finish-header">
										<div className="finish-casco">
											<Casco
												id={Math.round(Math.random() * 2000) +1}
												nick={"ME"}
												className="casco black-white"
											></Casco>
										</div>
										<div className="text-position">
										{ aciertos > 8?<>1<sup>{intl.formatMessage({ id: "app.awards.ord.1" })}</sup>{" "}</>:<></> }
										{ aciertos > 5 && aciertos < 9?<>2<sup>{intl.formatMessage({ id: "app.awards.ord.2" })}</sup>{" "}</>:<></> }
										{ aciertos >= 0 && aciertos < 6?<>3<sup>{intl.formatMessage({ id: "app.awards.ord.3" })}</sup>{" "}</>:<></> }
										{intl.formatMessage({ id: "app.quiz.position" })}
										</div>
									</div>
									<div className="finish-content">
										<span className="finish-icon-metrics">
											<img src={iconHit} />
										</span>
										<span className="border-right-gray">
											{aciertos}{" "}
											{intl.formatMessage({ id: "app.quiz.hits" })}
										</span>
										<span className="finish-icon-metrics ml-7">
											<img src={iconErrors} />
										</span>
										<span className="border-right-gray">
											{parseInt(numberQuestions) - parseInt(aciertos)}{" "}
											{intl.formatMessage({ id: "app.quiz.errors" })}
										</span>
										<span className="finish-icon-metrics ml-7">
											<img src={iconTimer} />
										</span>
										<span className="">
											{new Intl.NumberFormat('en-US').format(time)}&apos;&apos;
										</span>
									</div>
								</div>								
							</div>
							<div >
								<h2 style={ { textAlign: "center"} }>{ intl.formatMessage({ id:"app.demo.wow"})}</h2>
								<p style={ { textAlign: "center"} }>{ intl.formatMessage({ id:"app.demo.prove"})}</p>
							</div>
							<div className="footer-finish">
							{/*<Link to="/">{ intl.formatMessage({ id:"app.entrar"})}</Link>*/}
							<Button
								idButton="try-again"
								color="red"								
								clicked={() => {
									location.href='/demo'
								}}
								text={ intl.formatMessage({ id:"app.entrar"})}/>
							</div>
						</div>
					</main>
				</>
	}
    return	<>
			<main className="questions demo">
				<div className="dark-bg">
					<div className="questions-title mobile">
						<div className="left">
							<div className="flag_">
								<img src={WarmLap} />
							</div>
							<h2 className="gp-name">{ intl.formatMessage({ id:"app.demo.warmlap"})}</h2>
						</div>
						<div className="right">
							
						</div>      
					</div>  
					<Question qs={props.qs} flag={WarmLap} number={nQuestion} changeNumber={setNquestion} numberQuestions={numberQuestions} quizFinished={finishedQuiz} 
								showModal={props.showModal}
								setShowModal={props.setShowModal}/>
				</div>
			</main>
			</>
}

export default Questions
