import React from 'react'
import { useIntl } from 'react-intl'
//import { useNavigate } from 'react-router-dom'
//import {motion} from "framer-motion"
//import {FormattedMessage } from 'react-intl';
//import Success from '../../assets/images/success.png'
//import useWindowSize from '../hooks/useWindowSize'
import icon from '../../assets/icons/alert.svg'
import close from '../../assets/icons/close.svg'

const ModalRegister = (props) => {
  const intl = useIntl()
  
  //const size = useWindowSize();
  //const tablet = 768;
  //const y = size.width > tablet?"-50%":"0";
  //const x = size.width > tablet?"-50%":"0";

  return <div className="modal modalOK modalF1 modalRegister">
    <div  className="modalWrapper">
      <div className="modalContent">
            
            <div className="right">
              <img className="close" onClick={()=> { props.close() }} src={close} alt="close" />
              <div className="top" style={{ display: "flex", justifyContent: "space-between" }}>
                <h2>
                  {props.text}              
                </h2>
                <img src={icon} alt="icon" className="icon" />
              </div>
              <p>
              { intl.formatMessage({ id:"app.modal.regsiter.text"})}
              <strong>{ intl.formatMessage({ id:"app.modal.regsiter.text2"})}</strong>
              { intl.formatMessage({ id:"app.modal.regsiter.text3"})}
              <br/>
              <br/>
              </p>
              <button onClick={()=>{props.go()}} className="btn"> 
                {props.buttonText}
              </button>
            </div>
      </div>     
    </div>
  </div>
}

export default ModalRegister