import { React } from 'react'
import {motion} from "framer-motion"
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { setUser } from '../../actions'
//import useWindowSize from '../hooks/useWindowSize'

const ModalDelete = (props) => {  
  const intl = useIntl();
  const navigate = useNavigate();
  //const size = useWindowSize();
  //const tablet = 768;
  //const y = size.width > tablet?"-50%":"0";
  //const x = size.width > tablet?"-50%":"0";
  //console.log(size);

  return <div   className="modal modalOK modalF1 modalDelete">
    <motion.div animate={{ opacity:1, x: "-50%", y: "-50%", transition: { duration: 0.5, type: "spring", bounce: 0.5 }}} initial={{opacity:0, x: "-150%", y: "-50%"}} exit={{opacity:0, x:-600, transition: { duration: 0.2 } }} layout className="modalWrapper">
      <div className="modalContent">
            <div className="right">
                <h2>
                 { intl.formatMessage({ id:"app.profile.remove"})}      
                </h2>               
                <p>{ intl.formatMessage({ id:"app.profile.userremoved"})} </p>
                <p>{ intl.formatMessage({ id:"app.profile.sorry"})} </p>
                <div className="buttons">
                    <button onClick={()=>{ props.setUser(null); localStorage.removeItem("JWT"); navigate("/") }} className="btn"> 
                    { intl.formatMessage({ id:"app.continue"})}
                    </button>                        
                </div>               
            </div>
      </div>     
    </motion.div>
  </div>
}
//export default ModalDelete
const mapStateToprops = (state)=>{  
  return {...state};
}
export default connect(mapStateToprops,{setUser:setUser})(ModalDelete)