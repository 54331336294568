import {useIntl} from 'react-intl'
import Radio from '../common/Radio'

const TeamRadio = (props) => {
    var disabled="comodin"
    const intl = useIntl()

    if(props.status){
        disabled="comodin-gris"
    }

    return  <>
                <div className={disabled} onClick={props.onClickTeamRadio}>
                    <div className="icon-team">                        
                        <Radio/>
                    </div>
                    <div className="texto-team">{intl.formatMessage({ id: 'app.quiz.teamradio' })}</div>
                </div>
            </>
}
export default TeamRadio;