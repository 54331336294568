import React from 'react'
import { useIntl } from 'react-intl'
import useWindowSize from '../hooks/useWindowSize'
import imgHowParticipate from '../../assets/images/howparticipate.png'
import imgHowParticipateDesktop from '../../assets/images/howparticipate-desktop.png'

const HowParticipate = () => {
    const intl = useIntl() 
    const size = useWindowSize();
    const tablet = 768;

    return  <>
                <p>{intl.formatMessage({ id: "app.faq.howparticipate.text1" })}</p>
                <span><img src={size.width > tablet? imgHowParticipateDesktop : imgHowParticipate}/></span>
                <p>{intl.formatMessage({ id: "app.faq.howparticipate.text2" })}</p>
                <ul>
                    <li><strong>{intl.formatMessage({ id: "app.faq.howparticipate.seasontitle" })}</strong> {intl.formatMessage({ id: "app.faq.howparticipate.season" })}</li>
                    <li>
                        <strong>{intl.formatMessage({ id: "app.faq.howparticipate.teamtitle" })}</strong> {intl.formatMessage({ id: "app.faq.howparticipate.team" })}
                        <br/>
                        {intl.formatMessage({ id: "app.faq.howparticipate.team2" })}
                        <br/>
                        {intl.formatMessage({ id: "app.faq.howparticipate.team3" })}
                    </li>

                    <li><strong>{intl.formatMessage({ id: "app.faq.howparticipate.countrytitle" })}</strong> {intl.formatMessage({ id: "app.faq.howparticipate.country" })}</li>
                    <li><strong>{intl.formatMessage({ id: "app.faq.howparticipate.drawstitle" })}</strong> {intl.formatMessage({ id: "app.faq.howparticipate.draws" })}</li>
                </ul>
            </>
}
export default HowParticipate
