import React from 'react'
import {motion} from "framer-motion"
import useWindowSize from '../hooks/useWindowSize'

const ModalCustom = (props) => {
  const size = useWindowSize();
  const tablet = 768;
  const y = size.width > tablet?"-50%":"0";
  const x = size.width > tablet?"-50%":"0";

//<motion.div animate={{ opacity:1, transition: { duration: 0.2 }}} initial={{opacity:0}} exit={{opacity:0, transition: { duration: 0.2 } }} layout className="modal modalOK modalF1">
  return 	<div className={props.css !== undefined ? "modal modalOk modal"+props.css : "modal modalOk modalF1"}>
      <motion.div className="modalWrapper modalSinPadding" animate={{ opacity:1, x: x, y: y, transition: { duration: 0.5, type: "spring", bounce: 0.5 }}} initial={{opacity:0, scale:1, x:"-150%", y:"-50%"}} exit={{opacity:0, scale:1, transition: { duration: 0 } }} layout >
      <div className='modalCustomHeader'>
        <div className='start'>
          {
          props.iconHeader !== undefined ?
            <div><img src={props.iconHeader}/></div>
          :null
          }
        </div>
        <div className='end'>
          {
          props.onContinue !== undefined ? 
            <button className="btnCloseCorner" onClick={() => props.onContinue()}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.995 0C13.2615 0 13.5168 0.106441 13.7044 0.295676C14.0918 0.685551 14.0918 1.31521 13.7044 1.70509L8.414 6.998L13.7044 12.2907C13.8936 12.4784 14 12.7339 14 13.0004C14 13.2669 13.8936 13.5224 13.7044 13.7101C13.5159 13.8972 13.2606 14.0015 12.995 14C12.7295 14.0015 12.4742 13.8972 12.2856 13.7101L6.995 8.417L1.71438 13.7001C1.52746 13.8909 1.27199 13.9989 1.00497 14C0.739383 14.0015 0.484116 13.8972 0.295554 13.7101C0.106398 13.5224 0 13.2669 0 13.0004C0 12.7339 0.106398 12.4784 0.295554 12.2907L5.58 7.003L0.295554 1.71509C-0.0962441 1.32313 -0.0962441 0.687635 0.295554 0.295676C0.687351 -0.0962839 1.32258 -0.0962839 1.71438 0.295676L7 5.583L12.2856 0.295676C12.4732 0.106441 12.7286 0 12.995 0Z" fill="#444444"/>
              </svg>
            </button>
          :null
          }
        </div>
      </div>
      <div className="modalCustomTitle">
          <div><h2>{props.title}</h2></div>
          <div className="icon"><img src={props.icon}/></div>
      </div>
      <div className='modalCustomText'>
          {
          props.text !== undefined ?
            <div>{props.text}</div>
          :null
          }
          </div>
      <div className="modalFooter">
        {
        props.btnTextClose !== undefined ?
          <button className="btn-close btn-modal" onClick={()=>{props.onClose()}}>{props.btnTextClose}</button>
          : null 
        }
        {
        props.btnTextContinue !== undefined ?
          <button className="btn-continue btn-modal" onClick={()=> {props.onContinue()}} >{props.btnTextContinue}</button>
        : null
        }

      </div>
      </motion.div>
      </div>
 
}

export default ModalCustom