import React from 'react'

const Burger = (props) => {
    

    return <>
        <div className={props.open?"burger-icon open":"burger-icon"}>
            <div className="b b1"></div>
            <div className="b b2"></div>
            <div className="b b3"></div>            
        </div>
    </>
}
export default Burger