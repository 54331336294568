import React from 'react'

const Semaforo = (props) => {
    return <>
        <div className="semaforo-wrapper">
            <div className="bar"></div>
            <div className="semaforo-container">
                <div className="semaforo"></div >
                <div className={props.hide?"lights off":"lights"}>
                    <div className="light"></div>
                    <div className="light"></div>
                    <div className="light"></div>
                    <div className="light"></div>
                    <div className="light"></div>
                </div>
            </div>
        </div>
    </>
}
export default Semaforo