import axios from 'axios'

export const RespuestasEquipos = {
	post,
    getCheck,
    setCheck,
};

async function call(index, respuesta){
    var t = null;
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    await instance.post('api/team/respuesta', {"index": index, "respuesta":respuesta})
        .then(
            (data)=>{
                //console.log("data",data.data);
                t = data.data;
            }
        )
        .catch( ()=>{
            t = null;
        })
    return t;
}

async function callGetCheck(){
    var t = null;
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    await instance.get('api/team/check', {})
        .then(
            (data)=>{
                t = data.data;
            }
        )
        .catch( ()=>{
            t = null;
        })
    return t;
}

async function callSetCheck(){
    var t = null;
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    await instance.post('api/team/check', {})
        .then(
            (data)=>{
                t = data.data;
            }
        )
        .catch( ()=>{
            t = null;
        })
    return t;
}

async function setCheck(){    
    var retorno = await callSetCheck();
    return retorno;
}

async function getCheck(){    
    var retorno = await callGetCheck();
    return retorno;
}

async function post(index, respuesta){    
    var retorno = await call(index, respuesta);
    return retorno;
}