import React from 'react'
//import logof1 from '../../assets/images/f1-logo.svg'
import logos from '../../assets/images/mis-logos.svg'
//import logosantander from '../../assets/images/santander-logo.svg'
import left from '../../assets/images/chevron-left.svg'
import { Link } from 'react-router-dom'

const HeaderQuiz = () => { 

    return <><header className="header header-quiz">
      <div className="container">
        <div className="flex">
          <div className="left">
            <Link to="/" >
                <img src={left} className="logo_" alt="Back"/>
            </Link>
          </div>          
          <div className="right">
          <div  className="logo">
              <img src={logos} className="logo" alt="F1" />
            </div>
            {/*<div  className="logo">
              <img src={logof1} className="logo" alt="F1" />
            </div>
            <div  className="logo">
              <img src={logosantander} className="logo" alt="Banco Santander" />
            </div>*/}
          </div>          
        </div>
      </div>
    </header>
    </>
}
export default HeaderQuiz