
import { React, useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import Api from '../Api/Api'
import { useNavigate  } from 'react-router-dom'
import Arrow from '../../assets/images/red-arrow.svg'

const Code = (props) => {
    let navigate = useNavigate();
    const intl = useIntl();
    const [code, setCode] = useState(""); 
    const [error, setError] = useState(false); 
    const [wait, setWait] = useState(null); 
    const [btnDisabled, setBtnDisabled] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);  
    },[]);  

    const handleSubmit = (event) => { 
        setBtnDisabled(true)       ;
        Api.post('/api/login', { "id": props.id, "code": code.trim() })
        .then( (response) => {
            if (response.data.status === 200){  
                              
                window.dataLayer.push({
                    'event' : 'paso3',
                    'status' : 'CODIGO_EMAIL_EXITOSO'                    
                });              
                if (props.nickname !== null){
                    window.dataLayer.push({
                        'event' : 'paso3_registro_exito',
                        'status' : 'CODIGO_EMAIL_EXITOSO'
                    }); 
                } else {
                    window.dataLayer.push({
                        'event' : 'paso3_login_exito',
                        'status' : 'CODIGO_EMAIL_EXITOSO'
                    }); 
                }
                localStorage.setItem("JWT", response.data.data.token);                
                setBtnDisabled(false);
                //if (localStorage.getItem("F1-TEAM")){
                //    navigate("/welcome");  
                //} else {
                    navigate("/dashboard", {state: {blocked: "auth" } });                    
                //}
            }           
            
        })
        .catch(()=>{
            setBtnDisabled(false);            
            setError(true);            
        });
        event.preventDefault();
    }

    const sendAgain = () => { 
        Api.post('/api/prelogin', { "email": props.email }/*, { validateStatus: false}*/)
        .then( () => { 
            setWait(null);
            props.showModal();           
        })
        .catch(error=>{            
            if (error.response.data.error===403){
                setWait(parseInt(error.response.data.messages.error));
                setTimeout( ()=>{setWait(null)}, 3000);
            }
        })
    }

    const obscureEmail = (email) => {
        var splitarr=email.split('@')

        return "****@"+splitarr[1]
    }

return <>
    <div className='login-title'>
    <h3 className="title">
        <FormattedMessage
            id = "app.code.title"
            defaultMessage="Are you ready?"
        />
    </h3>
    <p>                            
        <FormattedMessage
        id = "app.code.text"
        defaultMessage="{nickname}! Enter the code sent to your email {email} so we can verify it's you and get ready to race your way!"
        values={{nickname: props.nickname !== null ? props.nickname: intl.formatMessage({ id: 'app.code.salute' }), email: obscureEmail(props.email) }}
        />
    </p>    
    </div>
    <div className='login-form'>
        <form onSubmit={event => handleSubmit(event) }>        
            <div className="formGroup relative">                            
                <input 
                    onChange={event => { setError(false); setCode(event.target.value)} } 
                    className="formControl" 
                    minLength={5}
                    maxLength={8}
                    onKeyDown={(e)=>{ if(e.key==="Enter"){ handleSubmit(e)} }}
                    type="text"                 
                />
                <label>{ intl.formatMessage({ id: 'app.codigo' }) }</label>            
                {error&&wait===null?<label className="error">
                    <FormattedMessage
                        id = "app.code.error"
                        defaultMessage="Invalid code"/>
                    </label>:null}
            </div>
            <div className='code-info'>
                <p className='codigo'> <FormattedMessage
                    id = "app.code.notreceive"
                    defaultMessage="Did yo not receive the code?"
                    />
                </p>
                <button  onClick={ (event)=>{sendAgain(); event.preventDefault();} } className="btn btnLink btnCodigo">
                    <FormattedMessage
                    id = "app.code.redlink"
                    defaultMessage="Click here to send a new one"
                    />
                    <img src={Arrow}></img>
                </button>
                {wait!==null?<div className="error">
                        <span><FormattedMessage
                            id = "app.code.error.wait"
                            defaultMessage="Wait for {minutes} minutes to try to login again."
                            values={{ minutes: wait }} />
                        </span>
                </div>:null}
            </div>
            <button id="signup-end" disabled={btnDisabled} className="btn btnPrimary btnCode"> <FormattedMessage
                id = "app.entrar"
                defaultMessage="Entrar"           
            /></button>
        </form>
    
    </div>
    <div className='footer'>
        <p className='codigo'> <FormattedMessage
            id = "app.code.notreceive"
            defaultMessage="Did yo not receive the code?"
            />
        </p>
        <button id="signup-end" onClick={ ()=>{sendAgain()} } className="btn btnLink btnCode">
            <FormattedMessage
            id = "app.code.redlink"
            defaultMessage="Click here to send a new one"
            />
            <img src={Arrow}></img>
        </button>
        {wait!==null?<div className="error">
                <span><FormattedMessage
                    id = "app.code.error.wait"
                    defaultMessage="Wait for {minutes} minutes to try to login again."
                    values={{ minutes: wait }} />
                </span>
        </div>:null}
    </div>
    </>
}
export default Code