import { React, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './components/Login'
import NoMatch from './components/NoMatch'
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import Quiz from './components/quiz/Quiz'
import Quizes from './components/quiz/Quizes'
import Rankings from './components/Ranking/Rankings'
import Awards from './components/Awards/Awards'
//import Teams from './components/Teams/Teams'
//import TheTeam from './components/Teams/TheTeam'
import Welcome from './components/Welcome'
import Intro from './components/Intro/Intro'
import Profile from './components/Profile/Profile'
import Demo from './components/Demo/Demo'
import Landing from './components/Landing/Landing'
import Sustainability from './components/Sustainability/Sustainability'
import Faq from './components/Faq/Faq'
import Legal from './components/Legal/Legal'
import LegalAdvice from './components/Legal/LegalAdvice'
import ComingSoon from './components/Landing/ComingSoon'
import Cookies from './components/Cookies/Cookies'
import CookieConsent from './components/Cookies/CookieConsent'
import PrivateRoute from './components/Auth/PrivateRoute'
import Test from './components/Test/Test'
import TestCheck from './components/Test/TestCheck'
import TestIntro from './components/Test/TestIntro'
import Team from './components/Test/Team'
import TeamChoose from './components/Test/TeamChoose'
import Ads from './components/Advertising/Ads'

function App() {
  const closed = false;
  const [page, setPage] = useState('dashboard')  

  return (       
    <BrowserRouter>
      <Header 
        page={page}        
      />      
      <Routes>
        {!closed && <Route path="/login" element={<Login setPage={setPage} />} />}
        <Route path="/" element={<Landing setPage={setPage} />} />
        {!closed && <Route path="/welcome" element={<Welcome setPage={setPage} />} /> }
        {!closed &&<Route path="/quiz/:id" element={<PrivateRoute redirectTo="/login"><Quiz setPage={setPage}/></PrivateRoute>} />}
        {!closed &&<Route path="/team/test" element={<PrivateRoute redirectTo="/login"><Test setPage={setPage} /></PrivateRoute>} />}
        {!closed &&<Route path="/team/intro" element={<PrivateRoute redirectTo="/login"><TestIntro setPage={setPage} /></PrivateRoute>} />}
        {!closed &&<Route path="/team/check" element={<PrivateRoute redirectTo="/login"><TestCheck setPage={setPage} /></PrivateRoute>} />}
        {!closed &&<Route path="/team/choose" element={<PrivateRoute redirectTo="/login"><TeamChoose setPage={setPage} /></PrivateRoute>} />}
        {!closed &&<Route path="/team" element={<PrivateRoute redirectTo="/login"><Team setPage={setPage} /></PrivateRoute>} />}
        {!closed &&<Route path="/ranking" element={<PrivateRoute redirectTo="/login"><Rankings setPage={setPage} /></PrivateRoute>} />}
        {/*closed && <Route path="/ranking" element={<Rankings setPage={setPage} />} />*/}
        <Route path="/my-prizes" element={<Awards setPage={setPage} />} />
        <Route path="/learn" element={<Sustainability setPage={setPage} />} />
        {!closed && <Route path="/dashboard" element={<PrivateRoute redirectTo="/login"><Quizes setPage={setPage} /></PrivateRoute>} />}
        {/*!closed && <Route path="/teams" element={<PrivateRoute redirectTo="/login"><Teams setPage={setPage} /></PrivateRoute>} />*/}
        {/*!closed && <Route path="/teams/:id" element={<PrivateRoute redirectTo="/login"><TheTeam setPage={setPage} /></PrivateRoute>} />*/}
        <Route path="/intro" element={<PrivateRoute redirectTo="/login"><Intro setPage={setPage} /></PrivateRoute>} />
        <Route path="/profile" element={<PrivateRoute redirectTo="/login"><Profile setPage={setPage} /></PrivateRoute>} />
        <Route path="/demo" element={<Demo setPage={setPage} />} />
        <Route path="/support" element={<Faq setPage={setPage} />} />
        <Route path="/cookie-policy" element={<Cookies setPage={setPage} />} />
        <Route path="/privacy-policy" element={<Legal setPage={setPage} />} />
        <Route path="/legal-advice" element={<LegalAdvice setPage={setPage} />} />
        <Route path="/legal" element={<LegalAdvice setPage={setPage} />} />
        <Route path="*" element={<NoMatch setPage={setPage} />} />
        <Route path="/comingsoon" element={<ComingSoon setPage={setPage} />} />
        {/*<Route path="*" element={<Landing setPage={setPage} />} />
        <Route path="/intro2" element={<Intro setPage={setPage} />} />*/}
      </Routes>    
     <Footer page={page} />   
     <CookieConsent/> 
     <Ads page={page} />
     
   </BrowserRouter>    
  )
}

export default App
