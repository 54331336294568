import axios from 'axios'

export const RankingAPI = {
	team,
};

async function call(){
    var t = null;
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    await instance.get('api/ranking/teams', {})
        .then(
            (data)=>{
                //console.log("RankingAPI", data);
                t = data.data.ranking;
            }
        )
        .catch( ()=>{
            t = null;
        })
    return t;
}



async function team(){    
    var retorno = await call();
    return retorno;
}