import { useEffect, useState, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import { useNavigate, Link } from  "react-router-dom"
import { TeamAPI} from '../Api/TeamAPI'
import { RespuestasEquipos } from '../Api/RespuestasEquipos'
import Slider from "react-slick"
import ModalVideo from './ModalVideo'
import ModalCheck from './ModalCheck'
import { Context } from "../lang/Wrapper"
//import Valentina from '../../assets/images/valentina_big.png'
import Ann_Suki from '../../assets/images/Annsuki.jpeg'
import Vicens from '../../assets/images/Vicens.jpg'
import Fercho from '../../assets/images/Fercho.jpeg'
import WTF from '../../assets/images/WTF1.jpg'
//import UK from '../../assets/images/uk.png'
//import BR from '../../assets/images/br.png'
import Play from '../../assets/images/play_video.svg'
import video_Ann_en from '../../assets/media/Annsuki_Sub_EN.mp4'
import video_Ann_es from '../../assets/media/Annsuki_Sub_ES.mp4'
import video_Vicens_en from '../../assets/media/Vicens_Sub_EN.mp4'
import video_Vicens_es from '../../assets/media/Vicens_Sub_ES.mp4'
import video_Fercho_en from '../../assets/media/Fercho_Sub_EN.mp4'
import video_Fercho_es from '../../assets/media/Fercho_Sub_ES.mp4'
import video_WTF_en from '../../assets/media/WTF1_Sub_EN.mp4'
import video_WTF_es from '../../assets/media/WTF1_Sub_ES.mp4'
//import sample_UK from '../../assets/media/sample1.m4v'
//import sample_BR from '../../assets/media/sample1.m4v'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'


const minuteSeconds = 60
const hourSeconds = 3600
const daySeconds = 86400

  const startTime = Date.now() / 1000 // use UNIX timestamp in seconds
  var endTime = startTime + 243248 // use UNIX timestamp in seconds

  var remainingTime = endTime - startTime
  var days = Math.ceil(remainingTime / daySeconds)
  var daysDuration = days * daySeconds

  const calculate = (d, h, m) =>{
    endTime = startTime + (d*24*3600) + (h*3600) + (m*60);
    remainingTime = endTime - startTime
    days = Math.ceil(remainingTime / daySeconds)
    daysDuration = days * daySeconds
  }

const timerProps = {
  isPlaying: true,
  size: 84,
  strokeWidth: 3
}

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <span className="num">{time}</span>
      <span>{dimension}</span>
    </div>
  );
};

//const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0
const getTimeDays = (time) => (time / daySeconds) | 0


const TestIntro = (props) => {
  let navigate = useNavigate();
  const intl = useIntl()
  const context = useContext(Context)
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [join, setJoin] = useState(false);
  const [showModal, setShowModal] = useState('')
  const [showModalCheck, setShowModalCheck] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [isCheckedDB, setIsCheckedDB] = useState(false)
  const [timeLeft, setTimeLeft] = useState(null)
  let settings = {
    arrows: false,    
    infinite: false,
    speed: 500,
    slidesToScroll: 1,    
    //centerMode: true,
    //centerPadding: "260px",
    responsive: [
      {
        breakpoint: 2400,
        settings: "unslick"
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          dots: false,
          swipeToSlide: false,
          //centerMode: true,
          //centerPadding: "50px",
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          dots: false,
          //centerMode: true,
          //centerPadding: "20px",
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: false,
          centerMode: true,
          centerPadding: "35px",
        }
      }
    ]
  };
  
  
  useEffect(() => {
    props.setPage("team");
    setLoading(true);
    setJoin(false);
    TeamAPI.get().then( data => {
      RespuestasEquipos.getCheck().then(acept=>{
        //console.log("acepto", acept);
        setIsCheckedDB(acept.acepto==='1');
      })
      TeamAPI.expired().then( expired =>{
        if (expired === true) {
          navigate("/team")
        } else{
          setTimeLeft({days: expired.days, hours: expired.hours, minutes:expired.minutes});
          calculate( expired.days, expired.hours, expired.minutes);
          if (data!== null){
            setLoading(false);
            setEnabled(true);
            if (data.length !== 0){
              navigate("/team")
            }
          }else {
            navigate("/")
          }
        }
      })      
    })   
  }, [])

  useEffect(() => {
    const timer = setInterval(() => setTimeLeft(
      prevState=>({
                    ...prevState,
                    days: prevState.hours===0?prevState.days-1:prevState.days,
                    hours: prevState.minutes===0?prevState.hours-1:prevState.hours, 
                    minutes: prevState.minutes>1?prevState.minutes-1:59
                  })
      )
    , 60000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  const openModal = (srcVideo) => {
    setShowModal(srcVideo)
  }

  const clickJoin = () =>{
    //setJoin(true); 
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
    if (isCheckedDB){
      navigate("/team/test"); 
    }else{
       navigate("/team/test"); 
    }
  }

  const startTest = () => {
    //console.log("start Test", isChecked);
    if (isChecked) {
      RespuestasEquipos.setCheck().then(acept=>{
        //console.log("acepto", acept);
        setIsCheckedDB(parseInt(acept.acepto)===1);
        navigate("/team/test");
      })
    }else 
      //navigate("/team/choose");
      setShowModalCheck(true);
  }



	return <main className="test test-intro">
    { (loading === true )?<div className="spinner-wrapper">< div className="spinner"/></div>:
    <div className="container">
      <div className="containerWrapper">
        {!join?<>
          <div className="top">
              <div className="top_left">
              <h2><FormattedMessage id = "app.test.playon" defaultMessage="Play on your favorite influencer&apos;s team."/></h2>
                <p><FormattedMessage id = "app.test.meet" defaultMessage="Meet the team leaders"/>
                <br />
                <FormattedMessage id = "app.test.run" /></p>
              </div>

              <div className="top_right">
                <button disabled={!enabled} className="btn button-red" onClick={ ()=>{ clickJoin();/*setJoin(true); window.scroll({top: 0, left: 0, behavior: 'smooth'});*/ } }>
                  <FormattedMessage id = "app.test.iwant" />
                </button>
                {timeLeft!==null?
                  <div className="chrono">
                  <p className="chrono_title">{/*Te quedan: 
                  (<span>{timeLeft.days} / </span>
                  <span>{timeLeft.hours} / </span>
                <span>{timeLeft.minutes}</span>)*/}
                  </p>

                  <div className="chrono_list">
                    <CountdownCircleTimer
                      {...timerProps}
                      colors="#EC0000"
                      duration={ /*daysDuration*/ daySeconds }
                      initialRemainingTime={ /*remainingTime*/ timeLeft.days}
                    >
                      {({ elapsedTime, color }) => (
                        <span style={{ color }}>
                          {renderTime(intl.formatMessage({ id:"app.cookies.days"}), getTimeDays(daysDuration - elapsedTime))}
                        </span>
                      )}
                    </CountdownCircleTimer>

                    <CountdownCircleTimer
                      {...timerProps}
                      colors="#EC0000"
                      duration={ daySeconds}
                      initialRemainingTime={ remainingTime % daySeconds}                      
                    >
                      {({ elapsedTime, color }) => (
                        <span style={{ color }}>
                          {renderTime(intl.formatMessage({ id:"app.cookies.hours"}), getTimeHours(daySeconds - elapsedTime))}
                        </span>
                      )}
                    </CountdownCircleTimer>

                    <CountdownCircleTimer
                      {...timerProps}
                      colors={"#EC0000"}
                      duration={hourSeconds}
                      initialRemainingTime={remainingTime % hourSeconds}
                      onComplete={(totalElapsedTime) => ({
                        shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds
                      })}
                    >
                      {({ elapsedTime, color }) => (
                        <span style={{ color }}>
                          {renderTime(intl.formatMessage({ id:"app.cookies.minute"}), getTimeMinutes(hourSeconds - elapsedTime))}
                        </span>
                      )}
                    </CountdownCircleTimer>
                  </div>
                </div>
                  :null}
            </div>
          </div>

          <Slider {...settings}>
            <div className="video">
              <div className="wrapper ann">
                <img src={Ann_Suki}/>

                <button onClick={() => openModal(context.locale.toUpperCase()==="ES" ? video_Ann_es : video_Ann_en)}>
                  <img src={Play}/>
                </button>
                <div className="data">
                  <h3><FormattedMessage id = "app.test.team"/> <FormattedMessage id = "app.test.influencer.1.name"/></h3>
                  <p><FormattedMessage id = "app.test.influencer.1.text"/></p>
                </div>
              </div>
            </div>
            <div className="video">
              <div className="wrapper vicens">
                <img src={Vicens}/>
                <button onClick={() => openModal(context.locale.toUpperCase()==="ES" ? video_Vicens_es : video_Vicens_en)}>
                  <img src={Play}/>
                </button>
                <div className="data">
                <h3><FormattedMessage id = "app.test.team"/> <FormattedMessage id = "app.test.influencer.2.name"/></h3>
                  <p><FormattedMessage id = "app.test.influencer.2.text"/></p>
                </div>
              </div>
            </div>
            <div className="video">
              <div className="wrapper fercho">
                <img src={Fercho}/>
                <button onClick={() => openModal(context.locale.toUpperCase()==="ES" ? video_Fercho_es : video_Fercho_en)}>
                  <img src={Play}/>
                </button>
                <div className="data">
                <h3><FormattedMessage id = "app.test.team"/> <FormattedMessage id = "app.test.influencer.3.name"/></h3>
                  <p><FormattedMessage id = "app.test.influencer.3.text"/></p>
                </div>
              </div>
            </div>
            <div className="video">
              <div className="wrapper wtf">
                <img src={WTF}/>
                <button onClick={() => openModal(context.locale.toUpperCase()==="ES" ? video_WTF_es : video_WTF_en)}><img src={Play}/></button>
                <div className="data">
                <h3><FormattedMessage id = "app.test.team"/> <FormattedMessage id = "app.test.influencer.4.name"/></h3>
                  <p><FormattedMessage id = "app.test.influencer.4.text"/></p>
                </div>
              </div>
            </div>
            {/*<div className="video">
              <div className="wrapper br">
                <img src={BR}/>
                <button onClick={() => openModal(sample_BR)}><img src={Play}/></button>
                <div className="data">
                <h3><FormattedMessage id = "app.test.influencer.5.name"/></h3>
                  <p><FormattedMessage id = "app.test.influencer.5.text"/></p>
                </div>
              </div>
        </div>*/}
          
          </Slider>
          <button disabled={!enabled} className="btn button-red" onClick={ ()=>{ clickJoin();/*setJoin(true); window.scroll({top: 0, left: 0, behavior: 'smooth'});*/ } }>
            <FormattedMessage id = "app.test.iwant" />
          </button>

          {showModal ?
            <ModalVideo 
              video={showModal}
              close={()=> {setShowModal(false)}} 
            /> : null}
        </>
        :<>
          {showModalCheck?<ModalCheck close={ ()=>{setShowModalCheck(false)} } 
            title={ intl.formatMessage({ id:"app.test.unlock"}) } 
            text= { intl.formatMessage({ id:"app.test.check"}) }
            buttonText="OK" />:null}
          <div className="top">
            <h2><FormattedMessage id = "app.test.compatibility" defaultMessage="Compatibility Test" /></h2>            
          </div>
          <p><FormattedMessage id = "app.test.complete" defaultMessage="Complete the following test to find out which team is the best fit for you." /></p>
          <div className="formGroup relative cb">
            <div className="flex">
              <input 
                type="checkbox" 
                id="cbStartTest" 
                className="formControl" 
                onChange={() => { setIsChecked(!isChecked) }} 
              />
              <label htmlFor="cbStartTest">  
                <FormattedMessage id = "app.test.iconsent"  values={{ br: <br /> }} />
                &nbsp;<Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">{ intl.formatMessage({ id: 'app.register.privacy' }) }</Link>
              </label>
            </div>
          </div>      
          <button className="btn button-red" onClick={ ()=>{ startTest(); } }><FormattedMessage id = "app.test.start" defaultMessage="Start test" /></button>
          <button className="btn button-link" onClick={ ()=>{ navigate("/team/choose"); } }>
            <FormattedMessage id = "app.test.select" />
          </button>
        </>}
      </div>
    </div>}
	</main>
}

export default TestIntro