import axios from 'axios'

export const PreguntasEquipos = {
	get,
};

async function call(){
    var t = null;
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    await instance.get('api/team/preguntas', {})
        .then(
            (data)=>{
                //console.log("data",data.data);
                t = data.data;
            }
        )
        .catch( ()=>{
            t = null;
        })
    return t;
}

async function get(){
    
    var retorno = await call();
    return retorno;
}