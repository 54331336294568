import React, {useState, useEffect, useContext} from "react";
import axios from 'axios'
import {Context} from "../lang/Wrapper";

const HeaderGP = (props) => {

    const [gp,setGp] = useState(null)
    const context = useContext(Context);

    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    useEffect(() => {
        instance.get('/api/gps').then( (response)=>{           

            response.data.gps.map( gp =>{
                if(gp.idgran_premio===props.idgp){
                   // console.log(gp)
                    setGp(gp)
                }
    
            })
        }).catch( (error)=>{
            console.log("Error:", error);
        })  
    }, []); 
  
    return  <>    
            {
            (gp !== null) ?
                <>
                    <img src={process.env.PUBLIC_URL + "../images/flags/" + gp.icon} />
                    <h2>
                        {                            
                            context.locale.toUpperCase()==="EN" ? gp.name + " GP™" : context.locale.toUpperCase()==="PL" ? "Grand Prix " + gp.name : "GP™ de " + gp.nombre
                        }
                    </h2>
                </>
                : null
            }
            </>
};

export default HeaderGP;
