import {React, useEffect, useRef, useState, useContext} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AnimatePresence, motion } from "framer-motion"
import {Context} from "../lang/Wrapper";
import useWindowSize from '../hooks/useWindowSize'
import logos from '../../assets/images/mis-logos.svg'
import logosantander from '../../assets/images/santander-logo.svg'
import left from '../../assets/images/chevron-left.svg'
import Menu from './Menu'
import support from '../../assets/images/support.svg'
import logout from '../../assets/images/logout.svg'
import {FormattedMessage} from 'react-intl'
import PropTypes from "prop-types"
import Burger from './Burger'
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import {Countries} from '../Login/Countries'
import Profile from './Profile'
import {UserAPI} from '../Api/UserAPI'

const Header = (props) => {
  const closed = false;
  const context = useContext(Context);
  const size = useWindowSize();
  const isMobile = size.width < 768;
  const scrollInto = useRef(null)
  let navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const getBack = () =>{
    
    if (['dashboard', 'teams', 'ranking', 'awards', 'landing', 'profile', 'sustainability'].includes(props.page)){
        return <button className="btn-burger" onClick={()=>{ setShowMenu(!showMenu) }}>      
              <Burger open={showMenu}/>
            </button>
    } else {      
      if (['intro', 'comingsoon'].includes(props.page)){
        return null
      }else{
        return <Link to="/" className="back">
                <img src={left} className="logo_back" alt="Back"/>
              </Link>
      }
    }
  }

  const getLogos = () => {
      return <>
        {
          ['awards'].includes(props.page) && isMobile ?
            <div className="awards">
              <span className="awards-logo"></span>
              <h2><FormattedMessage id = "app.menu.awards" defaultMessage="Awards" /></h2>
            </div>
          : null
        }
        {
          ['awards'].includes(props.page) && !isMobile ?
          <Link to="/" className="link-home">
            <div className="logo logo-santander">
              <img src={logosantander} className="logo logo-santander" alt="Banco Santander" />
            </div>
          </Link>
          : null
        }
        {
          !['awards'].includes(props.page) && !isMobile || !['awards'].includes(props.page) && isMobile ?
            <Link to="/" className="link-home">
              {/*<div className="logo">
                <img src={logof1} className="logo" alt="F1" />
              </div>
              <div className="logo">
                <img src={logosantander} className="logo" alt="Banco Santander" />
              </div>*/}
              <div className="logo">
                <img src={logos} className="logo" alt="Banco Santander F1 " />
              </div>
            </Link>
          : null
        }
      </>
  }
  
  function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  useEffect(() => {
    //console.log("header useEffect");
    if (localStorage.getItem("JWT") !== null){
      UserAPI.get().then((data)=>{
        if (data === null){
          localStorage.removeItem('JWT');
          localStorage.removeItem("INTRO");
          sessionStorage.removeItem("RELOAD");
          props.setUser(null);
          navigate("/");
        }
      });
      var j = parseJwt(localStorage.getItem("JWT"));
      //console.log("user", j.id);
      window.dataLayer.push({
        'userId' : j.id
      });
      //var date = new Date(j.exp * 1000);
    }else{
      window.dataLayer.push({
        'userId' : null
      });
    }
    window.dataLayer.push({
      'user_country' : Countries.getCountry()
    });
  })
  
  if (props.page==="quiz" ) return null;
  return <><header className="header"  ref={scrollInto}>
      <div className="container">
        <div className="flex">
          <div className="left">
            {getBack()} 
            <div className="desktop">
              { getLogos() }  
            </div>
          </div>          
          <div className="right">
            { getLogos() }
            <div className="desktop">
              {props.page !== "comingsoon"?
              <Menu red={false} setShowMenu={(val)=>{setShowMenu(val)}} page={props.page} version="desktop"/>:null
              }
            </div>                  
          </div>          
        </div>
      </div>
    </header>
    {(props.user !== null && !["profile", "team", "quiz"].includes(props.page))?
      <Profile view="header-view" user={props.user.id}/>
    :null}
    <AnimatePresence>
    {showMenu?
    <motion.div animate={{ opacity:1, x:0, transition: { duration: 0.5, type: "spring", bounce: 0.5 }}} initial={{opacity:0, x: -200}} exit={{opacity:0, x:-600, transition: { duration: 0.2 } }} layout className="menu">
      <Menu red={true} setShowMenu={(val)=>{setShowMenu(val)}} page={props.page} version="mobile"/>     

      <ul className="menu-footer">
        {!closed && <li className="">
          <Link to="/support"  onClick={()=>{ setShowMenu(false) }}>
            <span><FormattedMessage id = "app.menu.support" defaultMessage="Support" /></span>
            <img src={support} className="" alt="" />
          </Link>
        </li>}
        {props.user !== null && props.user.id !=null?
         <>{!closed &&<li>
          <button onClick={() => { localStorage.removeItem('JWT'); localStorage.removeItem("INTRO"); sessionStorage.removeItem("RELOAD"); props.setUser(null); setShowMenu(false); context.selectLanguage(navigator.language.includes("en")?"en":navigator.language.includes("pl")?"pl":"es");/*navigate("/");*/window.location.href="/";  } }>            
            <span><FormattedMessage id = "app.menu.logout" defaultMessage="Logout" /></span>
            <img src={logout} className="" alt="" />
          </button>
        </li>}</>:null}
      </ul>
    </motion.div>:null}
    </AnimatePresence>
    </>
}
Header.defaultProps = {
  showNav: null,  
};

Header.propTypes = {
  showNav: PropTypes.bool,
  
};

//export default Header
const mapStateToprops = (state)=>{        
  return {...state};  
}
//export default Login
export default connect(mapStateToprops,{setUser:setUser})(Header)