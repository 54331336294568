import axios from 'axios'

export const UserAPI = {
	get,
};

async function call(){
    var t = null;
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    await instance.get('api/user', {})
        .then(
            (data)=>{
                t = data.data.user;
            }
        )
        .catch( ()=>{
            t = null;
        })
    return t;
}




async function get(){    
    var retorno = await call();
    return retorno;
}
