import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from 'react-redux'

//var search = useLocation().search; 

function PrivateRoute( props/*{children, redirectTo}*/ ) {  
  const auth = props.user !== null;//true;//useAuth();    
  var url_string = (window.location.href).toLowerCase();
  var url = new URL(url_string);        
  //console.log(url);
  const c = url.searchParams.get('utm_campaign');
  const m = url.searchParams.get('utm_medium');
  const s = url.searchParams.get('utm_source');
  const c2 = url.searchParams.get('utm_content');
  //console.log(c, m,s);
  if (c !== null)  
  return auth ? props.children : <Navigate to={props.redirectTo+'?utm_campaign='+c+'&utm_medium='+m+'&utm_source='+s+'&utm_content='+c2} state={{ blocked:"no-auth"}} search={'utm_campaign='+c+'&utm_medium='+m+'&utm_source='+s+'&utm_content='+c2} />;
  else 
  return auth ? props.children : <Navigate to={props.redirectTo} state={{ blocked:"no-auth"}} />;
}

PrivateRoute.defaultProps = {
  children: null,
  redirectTo: "/login"
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
  redirectTo: PropTypes.string
};

const mapStateToprops = (state)=>{    
  return {...state};

}
//export default PrivateRoute;
export default connect(mapStateToprops)(PrivateRoute)