import React from 'react'

const Radio = () => {
    

    return <>
        <div className="radio-icon">
            <div className="r r1"></div>
            <div className="r r2"></div>
            <div className="r r3"></div>
            <div className="r r4"></div>
            <div className="r r5"></div>
            <div className="r r6"></div>
            <div className="r r7"></div>
        </div>
    </>
}
export default Radio