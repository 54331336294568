import axios from 'axios'

export const Perfil = {
	get,
    post,
    onePost
};

async function call(){
    var t = null;
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    await instance.get('api/perfil', {})
        .then(
            (data)=>{
                //console.log("data",data.data);
                t = data.data;
            }
        )
        .catch( ()=>{
            t = null;
        })
    return t;
}

async function callPost(info){
    var t = null;
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    await instance.post('api/perfil', {info})
        .then(
            (data)=>{
                //console.log("data",data);
                t = data.data;
            }
        )
        .catch( ()=>{
            t = null;
        })
    return t;
}

async function callOnePost(info){
    var t = null;
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    await instance.post('api/unPerfil', {info})
        .then(
            (data)=>{
                //console.log("data",data);
                t = data.data;
            }
        )
        .catch( ()=>{
            t = null;
        })
    return t;
}

async function get(){    
    var retorno = await call();
    return retorno;
}

async function post(info){
    
    var retorno = await callPost(info);
    return retorno;
}

async function onePost(info){
    
    var retorno = await callOnePost(info);
    return retorno;
}