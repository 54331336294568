import React, { useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Context } from "../lang/Wrapper"
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import axios from 'axios'
import {motion, AnimatePresence} from "framer-motion"
import Accordion from "../common/Accordion"
import HowParticipate from './HowParticipate'
import WhoParticipate from './WhoParticipate'
import HowQuizWork from './HowQuizWork'
import HowRankingWork from './HowRankingWork'
//import ManyFriendsInvite from './ManyFriendsInvite'
//import ManyTeamsParticipate from './ManyTeamsParticipate'
import LeaveTeam from './LeaveTeam'

const Faq = props => {
  const intl = useIntl()  
  const context = useContext(Context); 
  var instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 16000,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
  });

  const variants = {
    child0: {opacity:1, scale:1, x:0, transition: { delay:0, duration: 0.7, type: "spring", bounce: 0.2 }},
    child1: {opacity:1, scale:1, x:0, transition: { delay:0.3, duration: 0.7, type: "spring", bounce: 0.2 }},
    child2: {opacity:1, scale:1, x:0, transition: { delay: 0.6, duration: 0.7, type: "spring", bounce: 0.2 }},
    child3: {opacity:1, scale:1, x:0, transition: { delay: 0.9, duration: 0.7, type: "spring", bounce: 0.2 }},
    child4: {opacity:1, scale:1, x:0, transition: { delay:1.2, duration: 0.7, type: "spring", bounce: 0.2 }},
    child5: {opacity:1, scale:1, x:0, transition: { delay:1.5, duration: 0.7, type: "spring", bounce: 0.2 }},
    child6: {opacity:1, scale:1, x:0, transition: { delay: 1.8, duration: 0.7, type: "spring", bounce: 0.2 }},
    child7: {opacity:1, scale:1, x:0, transition: { delay: 2.1, duration: 0.7, type: "spring", bounce: 0.2 }}
}
  const QuestionsFaq = [
    {
        title: 'app.faq.whocanparticipate',
        content: <WhoParticipate/>
    },
    {
        title: 'app.faq.howcanparticipate',
        content: <HowParticipate/>
    },
    {
        title: 'app.faq.howquizwork',
        content: <HowQuizWork/>
    },
    {
        title: 'app.faq.howrankingwork',
        content: <HowRankingWork/>
    },
    /*{
        title: 'app.faq.manyfriendsinviteteam',
        content: <ManyFriendsInvite/>
    },
    {
        title: 'app.faq.manyteamsparticipate',
        content: <ManyTeamsParticipate/>
    },*/
    {
        title: 'app.faq.caniliveteam',
        content: <LeaveTeam/>
    }
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
    props.setPage('faq');  
    if (localStorage.getItem("JWT") !== null){
      instance.get('/api/user')
      .then( (response) =>{
          window.dataLayer.push({
              'userId' : response.data.user.id
          }); 
          props.setUser({"id": response.data.user});
          context.selectLanguage(response.data.user.idioma);   
      })
      .catch( () =>{   
      })        
    }          
  }, []); 
  

  return <main className="landing awards learn">
    
    <div className="bg-faq wrapper-awards ">
      <div className="info">
        <h2>
            {intl.formatMessage({ id: 'app.faq.title' })}
        </h2>
        <p>{intl.formatMessage({ id: 'app.faq.committed' })}</p>
      </div>
    </div>
    
    <section className="faq-questions">
        <AnimatePresence >
          <motion.ul className="" animate={{ transition: { delayChildren: 0.3 } }}>
                    {QuestionsFaq.map((q,key) =>
                      <motion.li variants={variants} animate={"child" + key} key={"qfaq-" + key} initial={{ opacity: 0, scale: 1, x: -200 }} exit={{ opacity: 0, scale: 1, transition: { duration: 0 } }} layout
                         name={'faq-1'}>
                            <Accordion title={q.title} content={q.content}/>
                    </motion.li>
                    )}
          </motion.ul>
		</AnimatePresence>    
    </section>
  </main>
}
const mapStateToprops = (state)=>{        
  return {...state};  
}
export default connect(mapStateToprops,{setUser:setUser})(Faq)