import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import Questions from "./Questions";
import { useIntl } from "react-intl";
import ModalCustom from "../common/ModalCustom";
import Semaforo from "../common/Semaforo";
import Button from "../common/Button";
import HeaderQuiz from "../common/HeaderQuiz";
import HeaderGp from "./HeaderGp";
//import Casco from "../common/Casco";
//import iconBestLap from "../../assets/icons/bestlap.svg";
//import iconHits from "../../assets/icons/hit.svg";
//import iconTimer from "../../assets/icons/timer.svg";
import iconPreviewFlag from "../../assets/icons/previewflag.svg";
import iconExit from '../../assets/icons/exit.svg'

const Quiz = (props) => {
  let { id } = useParams();
  let { state } = useLocation();
  const intl = useIntl();

  //const [record, setRecord] = useState(null);
  const [startQuestions, setStartquestions] = useState(state.goToFinish); //useState(false);
  const [showModal, setShowModal] = useState(false);
  const [finished, setFinished] = useState(state.goToFinish); //useState(false)
  const [hide, setHide] = useState(false);
  const [showNotPause, setShowNotPause] = useState(false);

  let navigate = useNavigate();

  //props.user.id.idioma
  var instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 16000,
    headers: { Authorization: "Bearer " + localStorage.getItem("JWT") },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    props.setPage("quiz");

    const imagenes=[
                'question-bg-desktop-1.png',
                'question-bg-desktop-2.png',
                'question-bg-desktop-3.png',
                'question-bg-desktop-4.png',
                'question-bg-desktop-5.png',
                'question-bg-desktop-6.png',
                'question-bg-desktop-7.png',
                'question-bg-desktop-8.png',
                'question-bg-desktop-9.png',
                'question-bg-desktop-10.png'
    ];

    var image= [];
    var dd=0;
    imagenes.forEach((url) => {
      image[dd] = new Image();
      image[dd].src = process.env.REACT_APP_URL+'/images/'+url;
      dd++;
    });

    image = null;
    //console.log(state);

    /*instance
      .get("/api/records/" + id)
      .then((response) => {
        response.data.records.forEach((item) => {
          if (parseInt(item.idgran_premio) === parseInt(id)) {
            setRecord(item);
          }
        });
      })
      .catch((error) => {
        console.log("Error:", error);
      });
      */
  }, []);

  const clickBack = () => {
    navigate("/dashboard");
  };

  const clickBackQuestions = () => {
    if (finished) {
      navigate("/dashboard")
    }else{
      instance.get('/api/quiz/canipause/'+id).then( (/*response*/)=>{           
        //console.log(response.data)
        
        setShowModal(true);

      }).catch( ()=>{
        //console.log("Error:", error);
        setShowNotPause(true)
      })  
    }
  }

  const start = () =>{
    setHide(true);
    setTimeout( ()=> {
      setStartquestions(true)
    }, 700);
  }

  if (startQuestions)
    return (
      <>
        <HeaderQuiz page={"quiz"} idioma={props.user.id.idioma} onClickBack={() => clickBackQuestions()} />
        {showNotPause ? 
            <ModalCustom icon={iconExit} 
						title={intl.formatMessage({ id: 'app.quiz.notpause' })} 
						btnTextContinue={intl.formatMessage({ id: 'app.quiz.modalcontinue' })} onContinue={()=> {setShowNotPause(false)}}/> 
				: null }
        <Questions
          numberQuiz={id}
          user={props.user}
          idgp={id}
          numeroPreguntas={process.env.REACT_APP_NUMERO_PREGUNTAS}
          showModal={showModal}
          setShowModal={setShowModal}
          setFinished={setFinished}
          alreadyFinished={state.goToFinish}
        />
      </>
    );

  return (
    <>
      <HeaderQuiz
        page={"quiz"}
        onClickBack={() => {
          clickBack();
        }}
      />
      <main className="quiz">

        <div className="quiz-title">
          {/*<div className="left">
                <HeaderGp idgp={id} idioma={props.user.idioma}/>
              //</div>*/}
          <div className="center">
            <h2 className="ready">
              {intl.formatMessage({ id: "app.quiz.ready" })}
            </h2>
          </div>
        </div>
        <div className="semaforo-content">
          <Semaforo hide={hide}/>
        </div>
        <div className="preview">
          <div className="gp-head">
          <div className="country">
            <HeaderGp idgp={id}  />
            </div>
            <h2 className="ready">
              {intl.formatMessage({ id: "app.quiz.ready" })}
            </h2>
          </div>
          <div className="preview-wrapper">
            <div className="panel">
              <div className="preview-header">
                <h2>
                  {process.env.REACT_APP_NUMERO_PREGUNTAS}{" "}
                  {intl.formatMessage({ id: "app.quiz.questions" })}
                </h2>
                <div className="preview-flagh">
                  <img src={iconPreviewFlag} />
                </div>
              </div>
              <div className="preview-content">
                <div className="preview-content-text">
                  <p>{intl.formatMessage({ id: "app.quiz.infoquiz" })}</p>
                </div>
              </div>
            </div>
          {/*
          {record !== null && record.totalTiempo !== null ? (
          <div className="record">
              <div className="lap">
                <img src={iconBestLap} />
              </div>
              <div className="casco">
                <Casco id={record.idusuario} nick={record.nickname} />
              </div>
              <div className="data">
                <div className="name">{record.nickname}</div>
                <div className="gp-records">
                  <span className="icon">
                    <img src={iconHits} />
                  </span>
                  <span className="border-right-gray">
                    {record.totalAciertos}{" "}
                    {intl.formatMessage({ id: "app.quiz.hits" })}
                  </span>
                  <span className="icon-timer">
                    <img src={iconTimer} />
                  </span>
                  <span className="">{record.totalTiempo}&apos;&apos;</span>
                </div>
          </div>
          </div>
          ) : null}
          */}
            <div className="footer-quiz">
              <Button
                idButton="quiz-start"
                color="red"
                clicked={() => {start()}}
                text={intl.formatMessage({ id: "app.quiz.letsgo" })}
              />
            </div>
        </div>
        </div>
        
      </main>
    </>
  );
};

//export default Quiz
const mapStateToprops = (state) => {
  return { ...state };
};
export default connect(mapStateToprops)(Quiz);
