import React from 'react'

const ModalCheck = (props) => {  

  return <div className="modal modalOK modalF1 modalCheck">    
    <div className="modalWrapper">
      <div className="modalContent">
            <div className="right">
              <div className="title">
                <h2>
                  {props.title}                  
                </h2>                
              </div>
                <p>
                  {props.text}                  
                </p>
                <div className="buttons">                  
                  <button onClick={()=>{props.close()}} className="btn"> 
                    {props.buttonText}
                  </button>
                </div>
            </div>
      </div>     
      </div>    
  </div>
}

export default ModalCheck