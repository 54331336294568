import {React, useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl';
import Teams from '../assets/images/teams.svg'
import { connect } from 'react-redux'
import { setUser } from '../actions'
import {Context} from "./lang/Wrapper"

const Welcome = (props) => {
    const intl = useIntl();
    const context = useContext(Context);
    const [teamData, setTeamData] = useState(null);
    let navigate = useNavigate();
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    useEffect(() => { 
        window.scrollTo(0, 0);
        props.setPage('welcome');
        instance.get('/api/user').then( (response)=>{            
            props.setUser({"id": response.data.user});
            context.selectLanguage(response.data.user.idioma);
            if (response.data.user.newUser){
                if (!localStorage.getItem('INTRO')){
                    navigate("/intro");
                }
            } else{
                localStorage.removeItem("INTRO");
            }          
        }).catch( (error)=>{
            console.log("Error:", error);
        })
        //Call API Endpoint and get team member
        instance.get('/api/team/details/'+localStorage.getItem('F1-TEAM')).then( (response)=>{        
            setTeamData(response.data.data);
          }).catch( (error)=>{
              console.log("Error:", error);
        })        
    },[]);

    const go = () =>{
        navigate("/teams");
    }

    return <main className="welcome">
        {teamData!==null?<>
            {teamData.vacantes?<>
                <h2>{ intl.formatMessage({ id:"app.welcome.welcome"})}</h2>
                <p><FormattedMessage 
                        id="app.welcome.invited" 
                        values={{nickname: teamData.nombre}} /></p>                
                <img src={Teams} />
                <p>{ intl.formatMessage({ id:"app.welcome.log"})}</p>
                <p>{ intl.formatMessage({ id:"app.welcome.log2"})}</p>
                <button onClick={()=>{ go() }}>
                { intl.formatMessage({ id:"app.welcome.lets"})}
                </button>
            </>:<>
            <h2>{ intl.formatMessage({ id:"app.welcome.welcome"})}</h2>
            <p>
                <FormattedMessage 
                        id="app.welcome.invited" 
                        values={{nickname: teamData.nombre}} /> 
               , { intl.formatMessage({ id:"app.welcome.but"})}</p>
            <img src={Teams} />
            <p>{ intl.formatMessage({ id:"app.welcome.dontworry"})}</p>
            <button onClick={()=>{ go() }}>
            { intl.formatMessage({ id:"app.welcome.regsiter"})}
            </button>
            </>}
        </>:null
        }
       
    </main>
}
//export default Welcome
const mapStateToprops = (state)=>{  
    return {...state};
  }
export default connect(mapStateToprops,{setUser:setUser})(Welcome)