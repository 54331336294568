import { React, useState } from "react";
import {useIntl} from 'react-intl'
import Casco from "../common/Casco";
import ModalCustom from "../common/ModalCustom";
import ShareButton from "../common/ShareButton";
import { AnimatePresence } from 'framer-motion'
import iconShare from "../../assets/icons/sharegray.svg";

const MyRanking = (props) => {  
    const [modal,setModal] = useState(false)
    const [url, setUrl] = useState(null)
    const intl = useIntl()

    const myTeam = () => {
        if (props.active === 2) {
          if (props.data.idusuarioCreador === props.user.id.idusuario) {
              return "my-team";
          }
        }
        return "not-my-team";
    };

    const copyLink = () => {
        //console.log(url)
        navigator.clipboard.writeText(url)
        setModal(false)
    }
    const show = () => {      
      console.log(props.data, props.active);
      //return true;
      if (props.data){
        var showMyRanking = true; 
        //console.log(props.data.posicion);
        if (props.data.posicion < 11){
          showMyRanking = false;
        }
        if (props.data.posicion === 0){
          showMyRanking = true;
        }
        /*if (props.active === 1) {
          showMyRanking = false;
        }*/
        return showMyRanking;      
      }else return false;
    }
  return (
        <>{show()?<>
            <AnimatePresence>
                { modal && url !== null?
                    <ModalCustom title={intl.formatMessage({ id: 'app.quiz.share.modaltitle' })} text={url}
                    onClose={copyLink} btnTextContinue={intl.formatMessage({ id: 'app.quiz.share.copy' })} onContinue={copyLink} />
                : null}
            </AnimatePresence>
      <div className={"my-ranking " + myTeam()}>
        <div className="position">
          <div className="order">
            <span>
              {props.data.totalAciertos === "-" ||
              props.data.posicion === 0 ||
              props.data.totalTiempo === null
                ? "-"
                : props.data.posicion}
            </span>
          </div>
          <div className="user-info">
            <div className="left">
              {parseInt(props.active) === 2 ? (
                <Casco
                  id={props.data.idusuarioCreador}
                  nick={props.data.nombre}
                  className={"casco"}
                ></Casco>
              ) : null}
              {parseInt(props.active) !== 2 ? (
                <Casco
                  id={props.data.idusuario}
                  nick={props.data.nickname}
                ></Casco>
              ) : null}
            </div>
            <div className="center">
              <span className="nick">
                {props.active === 2 ? props.data.nombre : props.data.nickname}
              </span>
              <div className="bottom">
                {Array.isArray(props.data) ? (
                  <>
                    <span className="hits">
                      {props.data[0].totalAciertos} hits
                    </span>
                    <span className="time">
                      {props.data[0].totalTiempo}&apos;&apos;
                    </span>
                  </>
                ) : (
                  <>
                    <span className="hits">
                      {props.data.totalAciertos} hits
                    </span>
                    <span className="time">
                      {props.data.totalTiempo}&apos;&apos;
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="right">
                {props.data.totalAciertos === "-" ||
                props.data.posicion === 0 ||
                props.data.totalTiempo === null ? null : (
                  <ShareButton
                    active={props.active}
                    gp={props.gp}
                    id={props.data.id}
                    icon={iconShare}
                    modalOutside={true}
                    setUrl={setUrl}
                    setModal={setModal}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
      </>:null}
    </>
  );
};
export default MyRanking;
