import {React, useEffect } from 'react'
import useWindowSize from '../hooks/useWindowSize'
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import { useIntl } from 'react-intl'
import LogoLapZero from '../../assets/images/logo-s-lap-zero.svg'
import { useNavigate } from 'react-router-dom'

const ComingSoon = (props) => {   
    const size = useWindowSize();
    const tablet = 768;    
    let intl = useIntl();   
    let navigate = useNavigate();

    useEffect(() => {
        props.setPage('comingsoon'); 
        navigate("/");       
    }, []);     
   
    const getHeight = (n, perc) => {
        if (size.width < tablet){            
            if (n===0 && perc > 0){
                return {minHeight: ( size.height*perc /100)+"px"};
            } else  {
                return {minHeight: (( size.height - n)*perc /100)+"px"};
            }            
        }
        return null;
    }      

    return <main className="landing">        
        <section className="landing-header"  style={getHeight(0, 100) }>
            <h1>
                {intl.formatMessage({ id: 'app.comingsoon' })}                
            </h1>
            <p>{intl.formatMessage({ id: 'app.landing.answer' })}</p>
            <div className="flex">  
                <span>&nbsp;</span>   
                <img className="logo" src={LogoLapZero} alt="" />
            </div>            
        </section>        
    </main>
}
const mapStateToprops = (state)=>{        
    return {...state};  
}
export default connect(mapStateToprops,{setUser:setUser})(ComingSoon)