import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {RankingAPI} from '../Api/RankingAPI'
//import Valentina from '../../assets/images/valentina.png'
//import James from '../../assets/images/james.png'
import Ann_Suki from '../../assets/images/Annsuki.jpeg'
import Fercho from '../../assets/images/fercho.png'
import Vicens from '../../assets/images/Vicens.jpg'
import UK from '../../assets/images//WTF1.jpg'
//import BR from '../../assets/images/br.png'

const Team = () => {  
  const [ranking, setRanking] = useState(null)
  const [loading, setLoading] = useState(true);  
  const intl = useIntl();

  useEffect(() => {    
    RankingAPI.team().then( data =>{
        //console.log("ranking", data);
        setRanking(data);
        setLoading(false);
    })
  }, [])

  const getOnlyName = (name) => {
    var arr = name.split(" ");
    return arr[0];
  }

  const getOnlySurname= (name) =>{
    //console.log(name);
    var arr = name.split(" ");
    if (arr.length>1) return arr[1];
    else return arr[0];
  }

  const getInfluencerName = (idequipo, name) =>{
    if (idequipo!==null){
      if (parseInt(idequipo)===1){
        return getOnlySurname(name);
      }else{
        return getOnlyName(name);
      }
    }
  }
 

	return <main className="test team-choose team">
    <div className="container">
      <div className="containerWrapper">
    { (loading === true )?<div className="spinner-wrapper">< div className="spinner"/></div>:
    <>
      <h2><FormattedMessage id = "app.intro.teams"/></h2>
      <div className="cards">
        {ranking !==null?
          ranking.Teams.ranking.map( (t, index) => {
            return <div key={t.nombre} 
              className={ranking.Teams.current_user[0].idequipo===t.idequipo?"card team-card ranking-card mine":"card team-card ranking-card"}>
              <div className="top">
                {parseInt(t.idequipo)===1?<img src={Ann_Suki} />:null}
                {parseInt(t.idequipo)===2?<img src={Vicens} />:null}
                {parseInt(t.idequipo)===3?<img src={Fercho} />:null}
                {parseInt(t.idequipo)===4?<img src={UK} />:null}
                {/*parseInt(t.idequipo)===5?<img src={BR} />:null*/}
                <div className={index===0?"mobile":""}>
                  <p>{ intl.formatMessage({ id:"app.test.teams"}, {influencer: getInfluencerName(t.idequipo, t.nombre) })} </p>
                  <p className="points"><span>{t.aciertos}</span> {intl.formatMessage({id:"app.quiz.hits"})}</p>
                </div>
              </div>
              
              <div style={{ width: "100%", display: "felx", flexDirection: "column"}}>
                {index===0?<div className="top desktop">
                  {/*<p>{ getOnlyName(t.nombre) }&apos;s Team</p>*/}
                  <p>{ intl.formatMessage({ id:"app.test.teams"}, {influencer: getInfluencerName(t.idequipo, t.nombre) })} </p>
                  <p className="points"><span>{t.aciertos}</span> {intl.formatMessage({id:"app.quiz.hits"})} </p>
                </div>:null}
                <div className="ranking">
                  { t.ranking.map( (i, index)=>{
                    return <div key={i.idusuario} className={ (i.idusuario === ranking.Teams.current_user[0].idusuario)?"item you":"item"}>
                    <div className="order"><span>{index+1}</span></div>
                    <div className="nick"><span>{i.nickname}</span></div>
                    <div className="points"><p><span>{i.aciertos}</span> {intl.formatMessage({id:"app.quiz.hits"})}</p></div>
                  </div>
                  })}             
                  {(ranking.Teams.current_user[0].idequipo===t.idequipo && ranking.Teams.current_user[0].posicion>3)?
                  <div className="item you">
                    <div className="order"><span>{ ranking.Teams.current_user[0].posicion }</span></div>
                    <div className="nick"><span>{ ranking.Teams.current_user[0].nickname }</span></div>
                    <div className="points"><p><span>{ ranking.Teams.current_user[0].totalAciertos}</span> {intl.formatMessage({id:"app.quiz.hits"})}</p></div>
                  </div>:null}
                </div>            
              </div>              
            </div>
          })    
        :null}
      </div>
    </>}
    </div>
    </div>
	</main>
}

export default Team