export const Countries = {
    getCountries,
    getCountry,      

};
const c =[{
    "name": "Argentina",    
    "alpha2Code": "AR",
    "alpha3Code": "ARG",    
    "languages": [
      {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
      },
      {
        "iso639_1": "gn",
        "iso639_2": "grn",
        "name": "Guaraní",
        "nativeName": "Avañe'ẽ"
      }
    ]    
  },
  {
    "name": "Brazil",    
    "alpha2Code": "BR",
    "alpha3Code": "BRA",
    "languages": [
      {
        "iso639_1": "pt",
        "iso639_2": "por",
        "name": "Portuguese",
        "nativeName": "Português"
      }
    ]    
  },
  {
    "name": "Chile",   
    "alpha2Code": "CL",
    "alpha3Code": "CHL",    
    "languages": [
      {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
      }
    ]
  },
  {
    "name": "Denmark",    
    "alpha2Code": "DK",
    "alpha3Code": "DNK",        
    "languages": [
      {
        "iso639_1": "da",
        "iso639_2": "dan",
        "name": "Danish",
        "nativeName": "dansk"
      }
    ]    
  },
  {
    "name": "Finland",    
    "alpha2Code": "FI",
    "alpha3Code": "FIN",            
    "languages": [
      {
        "iso639_1": "fi",
        "iso639_2": "fin",
        "name": "Finnish",
        "nativeName": "suomi"
      },
      {
        "iso639_1": "sv",
        "iso639_2": "swe",
        "name": "Swedish",
        "nativeName": "svenska"
      }
    ]    
  },
  {
    "name":"Germany",    
    "alpha2Code":"DE",
    "alpha3Code":"DEU",    
    "languages":[
      {
        "iso639_1":"de",
        "iso639_2":"deu",
        "name":"German",
        "nativeName":"Deutsch"
      }
    ]    
  },
  {
    "name": "Italy",    
    "alpha2Code": "IT",
    "alpha3Code": "ITA",
    "callingCodes": [
      "39"
    ],
    
    
    "languages": [
      {
        "iso639_1": "it",
        "iso639_2": "ita",
        "name": "Italian",
        "nativeName": "Italiano"
      }
    ]
  },
  {
    "name": "Mexico",    
    "alpha2Code": "MX",
    "alpha3Code": "MEX",    
    "languages": [
      {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
      }
    ]    
  },
  {
    "name": "Norway",    
    "alpha2Code": "NO",
    "alpha3Code": "NOR",
    "languages": [
      {
        "iso639_1": "no",
        "iso639_2": "nor",
        "name": "Norwegian",
        "nativeName": "Norsk"
      },
      {
        "iso639_1": "nb",
        "iso639_2": "nob",
        "name": "Norwegian Bokmål",
        "nativeName": "Norsk bokmål"
      },
      {
        "iso639_1": "nn",
        "iso639_2": "nno",
        "name": "Norwegian Nynorsk",
        "nativeName": "Norsk nynorsk"
      }
    ]    
  },
  {
    "name": "Poland",   
    "alpha2Code": "PL",
    "alpha3Code": "POL",
    "callingCodes": [
      "48"
    ],        
    "languages": [
      {
        "iso639_1": "pl",
        "iso639_2": "pol",
        "name": "Polish",
        "nativeName": "język polski"
      }
    ]    
  },
  {
    "name": "Portugal",    
    "alpha2Code": "PT",
    "alpha3Code": "PRT",    
    "languages": [
      {
        "iso639_1": "pt",
        "iso639_2": "por",
        "name": "Portuguese",
        "nativeName": "Português"
      }
    ]
  },
  {
    "name": "Spain",    
    "alpha2Code": "ES",
    "alpha3Code": "ESP",            
    "languages": [
      {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
      }
    ]
  },
  {
    "name": "Sweden",    
    "alpha2Code": "SE",
    "alpha3Code": "SWE",        
    "languages": [
      {
        "iso639_1": "sv",
        "iso639_2": "swe",
        "name": "Swedish",
        "nativeName": "svenska"
      }
    ]    
  },
  {
    "name": "United States of America",    
    "alpha2Code": "US",
    "alpha3Code": "USA",    
    "languages": [
      {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
      }
    ]
  },
  {
    "name": "Uruguay",   
    "alpha2Code": "UY",
    "alpha3Code": "URY",    
    "languages": [
      {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
      }
    ]
  },
  {
    "name":"United Kingdom",    
    "alpha2Code":"GB",
    "alpha3Code":"GBR",        
    "languages":[
      {
        "iso639_1":"en",
        "iso639_2":"eng",
        "name":"English",
        "nativeName":"English"
      }
    ]
  },
  {
    "name":"Greece",
    "alpha2Code":"GR",
    "alpha3Code":"GRC",
    "languages":[{"iso639_1":"el","iso639_2":"ell","name":"Greek (modern)","nativeName":"ελληνικά"}],
  },
  {
    "name":"Canada",
    "alpha2Code":"CA",
    "alpha3Code":"CAN",
    "languages":[{"iso639_1":"en","iso639_2":"eng","name":"English","nativeName":"English"}]    
  },
  {
    "name":"Belgium",
    "alpha2Code":"BE",
    "alpha3Code":"BEL",
    "languages":[{"iso639_1":"nl","iso639_2":"nld","name":"Dutch","nativeName":"Nederlands"},{"iso639_1":"fr","iso639_2":"fra","name":"French","nativeName":"français"},{"iso639_1":"de","iso639_2":"deu","name":"German","nativeName":"Deutsch"}]
  },
  {
    "name":"Switzerland",
    "alpha2Code":"CH",
    "alpha3Code":"CHE",
    "languages":[{"iso639_1":"de","iso639_2":"deu","name":"German","nativeName":"Deutsch"},{"iso639_1":"fr","iso639_2":"fra","name":"French","nativeName":"français"},{"iso639_1":"it","iso639_2":"ita","name":"Italian","nativeName":"Italiano"},{"iso639_2":"roh","name":"Romansh"}]
  },
  {
    "name":"Netherlands",
    "alpha2Code":"NL",
    "alpha3Code":"NLD",
    "languages":[{"iso639_1":"nl","iso639_2":"nld","name":"Dutch","nativeName":"Nederlands"}],
  },
  {
    "name":"Austria",
    "alpha2Code":"AT",
    "alpha3Code":"AUT",
    "languages":[{"iso639_1":"de","iso639_2":"deu","name":"German","nativeName":"Deutsch"}],
  },
  {
    "name":"France",    
    "alpha2Code":"FR",
    "alpha3Code":"FRA",
    "languages":[{"iso639_1":"fr","iso639_2":"fra","name":"French","nativeName":"français"}]
  },
]
function getCountries(){
    return c;
}
function getCountry(){
  var l = navigator.language;
  var parts = l.split("-");
  var co = parts[parts.length-1].toUpperCase();
  var found = "Other";  
  c.map((country) => {
      if (country.alpha2Code.toUpperCase() === co)
      found = country.name
  });    
  return { name: found, code:co, lang: l};
}