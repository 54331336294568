import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import {useNavigate, Link} from  "react-router-dom"
import {TeamAPI} from '../Api/TeamAPI'
import { RespuestasEquipos } from '../Api/RespuestasEquipos'

const TestCheck = (props) => {
  let navigate = useNavigate();
  const intl = useIntl()  
  const [loading, setLoading] = useState(true);
  
  const [isChecked, setIsChecked] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [isCheckedDB, setIsCheckedDB] = useState(false)
  
  useEffect(() => {
    props.setPage("team");
    setLoading(true);
    TeamAPI.get().then( data => {
      RespuestasEquipos.getCheck().then(acept=>{
        console.log("acepto", acept);
        setIsCheckedDB(acept.acepto==='1');        
        if (data!== null){
          setLoading(false);
          if (data.length !== 0){
            navigate("/team")
          }else {
            if (acept.acepto==='1'){
              navigate("/team/choose")
            }
          }    
        }else {
          navigate("/")
        }
      })
     
    })   
  }, []) 

  const startTest = () => {
    if (isChecked) {
      RespuestasEquipos.setCheck().then(acept=>{
        setIsCheckedDB(parseInt(acept.acepto)===1);
        navigate("/team/choose");
      })
    }else{
      navigate("/team/choose");
    }    
  }
 

	return <main className="test test-intro">
    { (loading === true )?<div className="spinner-wrapper">< div className="spinner"/></div>:
    <div className="container">
      <div className="containerWrapper">        
        <>             
          <div className="top">
            <h2><FormattedMessage id = "app.test.compatibility" defaultMessage="Compatibility Test" /></h2>            
          </div>
          <p>&nbsp;{/*<FormattedMessage id = "app.test.complete" defaultMessage="Complete the following test to find out which team is the best fit for you." />*/}</p>
          <div className="formGroup relative cb">
            <div className="flex">
              <input 
                type="checkbox" 
                id="cbStartTest" 
                className="formControl" 
                onChange={() => { setIsChecked(!isChecked) }} 
              />
              <label htmlFor="cbStartTest">  
                <FormattedMessage id = "app.test.iconsent"  values={{ br: <br /> }} />
                &nbsp;<Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">{ intl.formatMessage({ id: 'app.register.privacy' }) }</Link>
              </label>
            </div>
          </div>      
          <button className="btn button-red" onClick={ ()=>{ startTest(); } }><FormattedMessage id = "app.test.select" defaultMessage="Select Team" /></button>
          {/*<button className="btn button-link" onClick={ ()=>{ navigate("/team/choose"); } }>
            <FormattedMessage id = "app.test.select" />
          </button>*/}
        </>
      </div>
    </div>}
	</main>
}

export default TestCheck