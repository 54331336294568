import React from 'react'
import { useState, useEffect } from 'react';

const Chrono = (props) => {
     const [segundos,setSegundos] = useState(0)
     const [milesimas,setMilesimas] = useState(props.time)
     const [segHold,setSegHold] = useState(0)
     const [milHold,setMilHold] = useState(0)
     const tickInicial=17    

     const getTime = (milesimas) => {
          setMilesimas(milesimas+tickInicial)

          if(milesimas >= 900){
               setSegundos(segundos+1)
               setMilesimas(0)
          }
     };   

     useEffect(() => {
          if(props.status===2){
               setMilHold(milesimas)
               setSegHold(segundos)
               setSegundos(segundos-2);
          }          
     },[props.time,props.status]);

     useEffect(() => {  
          props.setTime(segundos, milesimas);
          const interval = setInterval(() => getTime(milesimas), tickInicial);
          return () => clearInterval(interval);          
     }, [milesimas]);
     
     return    <>
                    <span className='chrono-reloj'>
                         <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 8V13M20.75 13.25C20.75 18.08 16.83 22 12 22C7.17 22 3.25 18.08 3.25 13.25C3.25 8.42 7.17 4.5 12 4.5C16.83 4.5 20.75 8.42 20.75 13.25Z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M9 2H15" stroke="#FFFFFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                         </svg>
                    </span>
                    <span className='chrono-tiempo'>
                         <span className='chrono-segundos'>{props.status===2? segHold : segundos}</span>
                         <span className='chrono-milesimas'>
                              ,{props.status==2 ? milHold.toString().padStart(3,'0'):milesimas.toString().padStart(3, '0')}&rsquo;&rsquo;
                         </span>
                    </span>
               </>

}

export default Chrono