import React, { useState, useEffect} from 'react'
import ProgressBar from '../quiz/ProgressBar'
import CircleTimer from '../quiz/CircleTimer'
import Chrono from './Chrono'
import {useIntl} from 'react-intl'
import {motion, AnimatePresence} from "framer-motion"
import TeamRadio from '../quiz/TeamRadio'

const Question = (props) => {    
	const [status, setStatus]=useState("desconocido");
	const [colorOpcion, setColorOpcion]=useState(["color-opcion","color-opcion","color-opcion"])	
	const [number, setNumber]=useState(1)
	const [comodinUsado, setComodinUsado] = useState(false);
	const [statusChrono, setStatusChrono] = useState(0)	
	var time = 0;
	const intl = useIntl();
	const demoQuestions = props.qs/*[
		{
			"pregunta": intl.formatMessage({ id:"app.demo.q1"}),
			"opciones": [
				intl.formatMessage({ id:"app.demo.q1.a1"}),
				intl.formatMessage({ id:"app.demo.q1.a2"}),
				intl.formatMessage({ id:"app.demo.q1.a3"})
			],
			"index_correcta":1,
		},
		{
			"pregunta": intl.formatMessage({ id:"app.demo.q2"}),
			"opciones": [
				intl.formatMessage({ id:"app.demo.q2.a1"}),
				intl.formatMessage({ id:"app.demo.q2.a2"}),
				intl.formatMessage({ id:"app.demo.q2.a3"})
			],
			"index_correcta":0,
		},
		{
			"pregunta": intl.formatMessage({ id:"app.demo.q3"}),
			"opciones": [
				intl.formatMessage({ id:"app.demo.q3.a1"}),
				intl.formatMessage({ id:"app.demo.q3.a2"}),
				intl.formatMessage({ id:"app.demo.q3.a3"})
			],
			"index_correcta":0,
		},
		{
			"pregunta": intl.formatMessage({ id:"app.demo.q4"}),
			"opciones": [
				intl.formatMessage({ id:"app.demo.q4.a1"}),
				intl.formatMessage({ id:"app.demo.q4.a2"}),
				intl.formatMessage({ id:"app.demo.q4.a3"})
			],
			"index_correcta":2,
		}
	]*/
	const [n, setN] = useState(0);
	const [historico, setHistorico] = useState([]);	
	const [dQuestion,setDquestion] = useState(null);			

	const variants = {
		child0: {opacity:1, scale:1, x:0, transition: { delay:0, duration: 0.7, type: "spring", bounce: 0.5 }},
		child1: {opacity:1, scale:1, x:0, transition: { delay:0.3, duration: 0.7, type: "spring", bounce: 0.5 }},
		child2: {opacity:1, scale:1, x:0, transition: { delay: 0.6, duration: 0.7, type: "spring", bounce: 0.5 }},
		child3: {opacity:1, scale:1, x:0, transition: { delay: 0.9, duration: 0.7, type: "spring", bounce: 0.5 }}
	}	

	function getQuestion() {			
		if(n === 10){
			props.quizFinished(time, historico);
		}else{
			var data = null;
			data = demoQuestions[n];			
			setDquestion(data);
			setStatus(200);
			setStatusChrono(0);			
			setN(n+1);
		}		
	}

	useEffect(()=>{ 
		//console.log("props", props);
		window.scrollTo(0, 0);
		//pedimos pregunta
		//getDemoQuestions();	
		getQuestion()
			
	}, []);

	const colorRespuesta = (index,status) => {

		var color=colorOpcion
		if(status==="reset"){
			color[index]="color-opcion"
		}else{
			color[index]="color-opcion-"+status
		}
		setColorOpcion(color)
	}

	
	function responder(index){
		//console.log("responder", n);
		//solo mandamos la respuesta al servidor si no pulsa el comodin
		if( statusChrono!==2) {			
			var index_correcta=-1				
			var h = historico;
			//console.log(demoQuestions[n-1]["en"].pregunta, "respondi",index, "correcta", demoQuestions[n-1].index_correcta);
			index_correcta=demoQuestions[n-1].index_correcta;
			if(index===demoQuestions[n-1].index_correcta){
				//index_correcta=demoQuestions[n-1].index_correcta;
				colorRespuesta(index_correcta,"correcta");
				h.push('1');
				setHistorico(h);
			}else{	
				//console.log("respuesta error")				;
				h.push('0');
				setHistorico(h);
				colorRespuesta(index,"erronea");
				colorRespuesta(index_correcta,"correcta");
			}
			//setTimeChrono(response.data.tiempoTotal)
			setStatusChrono(2)
			//esperamos unos segundos y cambiamos de pregunta y volvemos al color original
			setTimeout( () => {					
				//colorRespuesta(index,"reset")
				//colorRespuesta(index_correcta,"reset")
				colorRespuesta(0,"reset")
				colorRespuesta(1,"reset")
				colorRespuesta(2,"reset")
				setStatusChrono(1)
				if (n >= 10){
					props.quizFinished(time, historico);
				}else{
					if(props.number!==props.numberQuestions){
						getQuestion();
					}
				}
			},2000);
			
			setNumber(Math.min(number+1, 10))
			if(number>props.numPreguntas){
				setNumber(props.numPreguntas)
			}					
		}	
		//console.log("time:",time);	
	}

	const finishTimer = () => {
		if(number !== props.numPreguntas){	
			var h = historico;	
			h.push('0');
			setHistorico(h)	
			getQuestion()			
			setNumber(Math.min(number+1, 10))
		}		
		//console.log("time:",time);
	}

	const usarComodin = () =>{
		setComodinUsado(true);
		//Poner en rojo una incorrecta
		var quitar = dQuestion["index_correcta"]===0?1:0;
		colorRespuesta(quitar,"erronea");
	}
	const setTimeValue = (s, ms) => {
		time = (s*1000)+ms;
	}

	if(status!==200){
		//return <Modal title="Error al comunicarse con el servidor" message="Error {status}"/>
		return null
	}	

	return  <> 
				{demoQuestions.length === 10?<>	
				<div className="questions-title desktop">
						<div className="left">
							<div className="flag_">
								<img src={props.flag} />
							</div>
							<h2 className="gp-name">{ intl.formatMessage({ id:"app.demo.warmlap"})}</h2>
						</div>
						<div className="right">
							<ProgressBar historico={historico} numPreguntas={10} /> 			
						</div>      
				</div>  			
				<div className="question">
				<ProgressBar historico={historico} numPreguntas={10} /> 
					<div className="headerQuestion">
						<div className="numberContainer">
							<div className="circleContainer">
								<CircleTimer key={n} time={20} duration="20" number={number} onFinish={finishTimer}><></></CircleTimer>
							</div>
							<div className='txtOf'>{intl.formatMessage({ id: 'app.quiz.of' }) + " " + props.numberQuestions}</div>
						</div>
					
						<div className="chronoContainer">
							<Chrono time={0} status={statusChrono} setTime={ (s, ms)=>setTimeValue(s, ms)}/>
						</div>
					</div>
					<div className="containerQuestion">
						<div className="pregunta">
							{dQuestion.pregunta}							
							<div className='teamradio-desktop'>	
								<TeamRadio status={comodinUsado} onClickTeamRadio={usarComodin}/>
							</div>
							
						</div>						
						<AnimatePresence >
							<motion.ul className="opcionWrapper" animate={{ transition:{delayChildren:0.3}}}>
							{dQuestion.opciones.map((op,key) =>
								dQuestion.opciones!=='' ?
									<motion.li style={{ minHeight: "33.333%"}} variants={variants} animate={"child"+key} initial={{opacity:0, scale:1, x: -200}} exit={{opacity:0, scale:1, transition: { duration: 0 } }} layout 
									name={'opcion-'+key} className={'opcion ' + colorOpcion[key]} key={op+key} onClick={(e)=> {
										responder(key,e)
									}}>{op}</motion.li>
								:''
							)}
							</motion.ul>
						</AnimatePresence>
						<div className='teamradio-mobile'>	
							<TeamRadio status={comodinUsado} onClickTeamRadio={usarComodin}/>
						</div>						
					</div>					
				</div>
				</>:<></>}
            </>
}

export default Question