import {FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const Intro = ({ setTypeLogin }) => {

	return <>
		<div className='login-title'>
			<h2 className="title">
				<FormattedMessage
					id="app.login.intro.title"
					defaultMessage="Start your race"
				/>
			</h2>
			<p>
				<FormattedMessage id="app.login.text" />
				<br/>
				<strong><FormattedMessage id="app.login.text2" /></strong> 
				<FormattedMessage id="app.login.text3" />
			</p>
		</div>

		<div className='login-form mod-intro'>
			<button type="button" className="btn btnPrimary" onClick={() => setTypeLogin(prevState => ({ ...prevState, intro: false, register: true }))}>
				<FormattedMessage
					id="app.login.createaccount"
					defaultMessage="Create account"
				/>
			</button>

			<p><FormattedMessage
				id="app.login.haveaccount"
				defaultMessage="Do you already have an account?"
			/> <Link style={{ color: "#fff", textDecoration: "underline"}} to="#" className="white" onClick={(e) => { setTypeLogin(prevState => ({ ...prevState, intro: false, email: true })); e.preventDefault(); } }>
				<strong><FormattedMessage id="app.login.signin" defaultMessage="Sign in" /></strong>
				</Link>
			</p>

			{/*<button type="button" className="btn btnPrimary white" onClick={() => setTypeLogin(prevState => ({ ...prevState, intro: false, email: true }))}>
				<FormattedMessage
					id="app.login.signin"
					defaultMessage="Sign in"
				/>
			</button>*/}
		</div>
	</>
}

export default Intro