import {React, useState} from 'react'
import {FormattedMessage, useIntl } from 'react-intl'
import Api from '../Api/Api'
import { validateEmail } from '../../utils/utils'

const Email = ({ onRegister, onContinue, invalidMail, setTypeLogin }) => {
    const intl = useIntl()
    const [email, setEmail] = useState(invalidMail===null?"":invalidMail) 
    const [badMail, setBadMail] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [errorWait, setErrorWait] = useState(null) 
    const [errorServer, setErrorServer] = useState(null)

    const handleSubmit = (event) => {
        setBtnDisabled(true)
        
        if (validateEmail(email)){
            
            Api.post('/api/prelogin', { "email": email }, { validateStatus: false})
                .then( (response) => {
                    if(response.data.status===500){
                        setErrorServer(true)
                    }                   
                    if (response.data.status === 200){ 
                        window.dataLayer.push({
                            'event' : 'paso1_login_exito',
                            'status' : 'EMAIL_EXITOSO',
                            'action': 'login'
                        })   
                        window.dataLayer.push({
                            'event' : 'paso1',
                            'status' : 'EMAIL_EXITOSO',
                            'action': 'login'
                        })                       
                        onContinue(email, response.data.data.id)
                        setBtnDisabled(false)
                        setTypeLogin(prevState => ({ ...prevState, email: false, code: true }))
                    }
                    if (response.data.status === 404){
                        window.dataLayer.push({
                            'event' : 'paso1_registro_exito',
                            'status' : 'EMAIL_EXITOSO',
                            'action': 'register'
                        })  
                        window.dataLayer.push({
                            'event' : 'paso1',
                            'status' : 'EMAIL_EXITOSO',
                            'action': 'register'
                        })   
                        onRegister(email)
                        setBtnDisabled(false)
                    }
                    if(response.data.status === 403){
                        //console.log(response)
                        if (!isNaN(response.data.error) ){
                            let waitTime=parseInt(response.data.messages.error)*60000
                            setTimeout( () => {
                                setBtnDisabled(false)
                                setErrorWait(null)
                            },waitTime)                            
                            setErrorWait(response.data.messages.error)
                        }
                    }
                    //setBtnDisabled(false)
                }).catch((error)=>{
                  console.log(error)  
                  setBtnDisabled(false)
                })
        }else{
            setBadMail(true)
            setBtnDisabled(false)
        }
        event.preventDefault()
    }

return <>
    <div className='login-title'>
        <h2 className="title">
            <FormattedMessage
                id = "app.login.title"
                defaultMessage="Login in 1, 2, 3"
            />
        </h2>
        <p>                            
            <FormattedMessage id = "app.login.mail.entertocreate1" />
            <br/>
            <strong><FormattedMessage id = "app.login.mail.entertocreate2" /></strong>
        </p>   
    </div>
    <div className='login-form'>
    <form onSubmit={event => handleSubmit(event) }>                                      
        <div className="formGroup relative">   
        <input onChange={event => { setEmail(event.target.value); setBadMail(false)} } className="formControl" type="email" 
            value={email}
            minLength={5}
            maxLength={100}
            placeholder={ /*intl.formatMessage({ id: 'app.login.mail.placeholder' })*/'' } />
            <label>{intl.formatMessage({ id: "app.correo" })}</label>
            { (invalidMail===null&&!badMail)?null:
            <label className="error error-mail">{intl.formatMessage({ id: "app.register.error.invalidMail" })}</label>}
            
            {errorWait !== null ? <span className="error error-mail">
                    {errorWait < 10 ?
                        <FormattedMessage
                            id="app.code.error.wait"
                            defaultMessage="Wait for {minutes} minutes to try to login again."
                            values={{ minutes: errorWait }} />
                        :
                        <FormattedMessage
                            id="app.code.error.waituntiltomorrow"
                            defaultMessage="Come back tomorrow to login again."
                            />
                    }
            
            </span> : null} 
            {errorServer!==null?<span className="error error-mail">                    
                <FormattedMessage
                            id="app.code.error.server"
                            defaultMessage="We are experiencing problems sending emails, Please try again later."
                            />
                </span>:null
            }     
        </div>
        <button id="login-start" disabled={btnDisabled} className="btn btnPrimary btnLogin"> <FormattedMessage
            id = "app.continue"
            defaultMessage="Continue"
        /></button>
    </form>
    </div>
    </>
}
export default Email