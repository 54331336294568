import React, { useEffect, useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Context } from "../lang/Wrapper"
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import Slider from "react-slick";
import Item from './Item';
import axios from 'axios'
import Vegas from '../../assets/images/las-vegas.png'
import SignedHelmet from '../../assets/images/replica.png'
import Watch from '../../assets/images/watch.png'
import Helmet from '../../assets/images/1_casco_miniatura.png'
import Glasses from '../../assets/images/glasses.png'
import Ferrari from '../../assets/images/ferrari.png'
import Merch from '../../assets/images/3_pack_merchan.png'
import Merch2 from '../../assets/images/merch2.png'
import ArrowLeft from '../../assets/images/icon-chevron_left.svg'
import ArrowRight from '../../assets/images/icon-chevron_right.svg'

const Awards = props => {
  const intl = useIntl()
  const context = useContext(Context); 
  const SlickNextArrow = ({ currentSlide, slideCount, ...props }) => (
    <img src={ArrowRight}
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
    </img>
);
const SlickPrevArrow = ({ currentSlide, slideCount, ...props }) => (
    <img src={ArrowLeft}
      {...props}
      className={
        "slick-prev slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
    </img>
  );

  let settings = {
    customPaging: function(i) {
      return (
        <div className={"dot "+ i}></div>
      )
    },
    arrows: true,
    prevArrow: <SlickPrevArrow/>,//<img src={Arrow} />,
    nextArrow: <SlickNextArrow/>,//></SlickArrowRight><img src={Arrow} />,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    //variableWidth: true,
    centerMode: true,
    centerPadding: "260px",
    responsive: [
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 1,
          dots: true,
          swipeToSlide: false,
          centerMode: true,
          centerPadding: "260px",//"260px",
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          dots: true,
          swipeToSlide: false,
          centerMode: true,
          centerPadding: "100px",//"260px",
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          swipeToSlide: false,
          centerMode: true,
          centerPadding: "0px",//"260px",
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          centerMode: true,
          centerPadding: "40px",
        }
      }
    ]
  };
var instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 16000,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
});
  
  useEffect(() => {
    window.scrollTo(0, 0);
    props.setPage('awards');
    if (localStorage.getItem("JWT") !== null){
      instance.get('/api/user')
      .then( (response) =>{
          window.dataLayer.push({
              'userId' : response.data.user.id
          }); 
          props.setUser({"id": response.data.user});
          context.selectLanguage(response.data.user.idioma);   
      })
      .catch( () =>{   
      })        
    }
  }, []); 

  return <main className="awards">    
    <div className="wrapper-awards season">
      <div className="info">
        <h2>
            <FormattedMessage id = "app.awards.seasonchamp" defaultMessage="Season Champioship" />
        </h2>
        <p><FormattedMessage id = "app.awards.participate" defaultMessage="" /></p>
      </div>
    </div>

    <div className="carousel">
      <div className="content">
        <Slider {...settings}>
          <Item image={Vegas} user={props.user !== null? props.user.id:null} title="" number="1" subtitle={intl.formatMessage({ id: 'app.awards.trip' })} />
          <Item image={SignedHelmet} user={props.user !== null? props.user.id:null} title="" number="2" subtitle={intl.formatMessage({ id: 'app.awards.helmet' })} text={intl.formatMessage({ id: 'app.awards.replica' })}/>
          <Item image={Watch} user={props.user !== null? props.user.id:null} title="" number="3" subtitle={intl.formatMessage({ id: 'app.awards.watch' })} text={intl.formatMessage({ id: 'app.awards.fwatch' })}/>
        </Slider>
      </div>
    </div>

    <div className="wrapper-awards country">
      <div className="info">
        <h2>
            <FormattedMessage id = "app.awards.countrychamp" defaultMessage="Season Champioship" />
        </h2>
        <p><FormattedMessage id = "app.awards.special" defaultMessage="" /></p>
      </div>
    </div>
    
    <div className="carousel">
      <div className="content">
        <Slider {...settings}>
          <Item image={Helmet} user={props.user !== null? props.user.id:null} title="" number="1" subtitle={intl.formatMessage({ id: 'app.awards.tinyhelmet' })} text={intl.formatMessage({ id: 'app.awards.tinyreplica' })} />
          <Item image={Glasses} user={props.user !== null? props.user.id:null} title="" number="2" subtitle={intl.formatMessage({ id: 'app.awards.glasses' })}  text={intl.formatMessage({ id: 'app.awards.fglasses' })} />
          <Item image={Merch} user={props.user !== null? props.user.id:null} title="" number="3" subtitle={intl.formatMessage({ id: 'app.awards.merch' })}  text={intl.formatMessage({ id: 'app.awards.fmerch' })} />
        </Slider>
      </div>
    </div>

    <div className="wrapper-awards team">
      <div className="info">
        <h2>
            <FormattedMessage id = "app.awards.teamchamp" defaultMessage="Championship team" />
        </h2>
        <p><FormattedMessage id = "app.awards.teammaster" defaultMessage="" /></p>
      </div>
    </div>

    <div className="carousel image">
        <Item image={Ferrari} user={props.user !== null? props.user.id:null} title="" number="1" subtitle={intl.formatMessage({ id: 'app.awards.vip' })}  text={intl.formatMessage({ id: 'app.awards.travelwith' })} />
    </div>

    <div className="wrapper-awards draws">
      <div className="info">
        <h2>
            <FormattedMessage id = "app.awards.drawschamp" defaultMessage="Championship draws" />
        </h2>
        <p><FormattedMessage id = "app.awards.foreach" defaultMessage="" /></p>
      </div>
    </div>

    <div className="carousel image">
        <Item image={Merch2} user={props.user !== null? props.user.id:null} title={intl.formatMessage({ id: 'app.awards.winner' })}  number="0" subtitle="" text={intl.formatMessage({ id: 'app.awards.random' })} />        
    </div>
  </main>
}
const mapStateToprops = (state)=>{        
  return {...state};  
}
export default connect(mapStateToprops,{setUser:setUser})(Awards)