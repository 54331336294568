import { useState, useEffect } from 'react'

const StepMultiple = ({ step, dataFilteredByStep, onOptionChange }) => {
  const [dataStep, setDataStep] = useState([])

  useEffect(() => {
    if (dataFilteredByStep.length > 0) {
      setDataStep(dataFilteredByStep[0].answers)
    }
  }, [dataFilteredByStep])

  return <>
  <h2>{dataFilteredByStep[0]?.question}</h2>
    <div className="test_step1 multiple">
      {/*<div className="test_question">
        <p>{dataFilteredByStep[0]?.question}</p>
      </div>*/}

      <div className="test_answer">
        <ul>
          {
            dataStep?.map((item, idx) => {

              return <li key={`answer-m-${step}-${idx}`}>
                <input
                  type="checkbox"
                  id={`cbTest-${step}-${idx}`}
                  name="cbsTest"
                  value={item}
                  //checked={isChecked === item}
                  onChange={onOptionChange}
                />
                <label className="cb multiple" htmlFor={`cbTest-${step}-${idx}`}><span>{item}</span></label>
              </li>
            })
          }
        </ul>
      </div>
  </div>
  </>
}

export default StepMultiple